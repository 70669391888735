<template>
  <component :is="getLayout">
    <router-view :key="$route.name + ($route.params.person_hash || '')"/>
  </component>
</template>

<script>
const default_layout = 'default'

import {onBeforeUnload} from "@/utils/icons"
import AppLayout from "@/components/layouts/AppLayout";
import SiteLayout from "@/components/layouts/SiteLayout";
import CleanLayout from "@/components/layouts/CleanLayout";


export default {
  name: 'App',
  methods: {},
  computed: {
    getLayout() {
      const route_layout = this.$route.meta["layout"] || default_layout

      const layouts = {
        "without-layout": CleanLayout,
        'default-layout': AppLayout,
        'site-layout': SiteLayout
      }
      return layouts[`${route_layout}-layout`]
    },
    getLayout_() {
      const route_layout = this.$route.meta["layout"] || 'default'

      switch (route_layout) {
        case 'without':
          return () => import("@/components/layouts/CleanLayout")
        case 'default':
          return () => import("@/components/layouts/AppLayout")
        case 'site':
          return () => import("@/components/layouts/SiteLayout")
      }

      return null

    },
  },
  created() {
    window.addEventListener('beforeunload', onBeforeUnload)
    window.addEventListener('beforeinstallprompt', (e) => {
      this.$store.commit('BEFORE_INSTALL_PROMPT', e)
    });
  },
};
</script>

<style lang="scss">
$body-font-family: 'MacPawFixelDisplay';
$border-color-main: #cdcdcd;

$border-color: #cbcbcb;
$border-archive-color: #e6e6e6;

$form-table-border-color: rgba(203, 203, 203, 0.5);
$form-table-header-border-color: rgba(203, 203, 203, 0.3);

$table-report-border-color: #cbcbcb;

.dialog-without-box-shadow {
  box-shadow: none !important;
}

.toolbar-work-place-title{
  font-size: 1.18rem;
  font-weight: 500;
}

.mini-report-button {
  .v-btn__content {
    font-size: .78rem !important;
  }
}

.mini-solo-field {
  .v-input__control {
    min-height: 36px !important;
    .v-label {
      font-size: 14px !important;
    }
    .v-select__selection.v-select__selection--comma {
      font-size: 14px !important;
    }
  }
}

.login-tabs {
  .v-slide-group__prev.v-slide-group__prev--disabled {
    display: none !important;
  }
}

:root {
  --pt-border-color: rgba(0, 0, 0, .3);
  --pt-border-row-color: rgba(0, 0, 0, .12);
  --pt-border-width: 1px;
  --pt-expand-border-color: rgba(0, 0, 0, .05);
  --pt-expand-background-col: #f0f0f0;
  --pt-error-color: #f09995;
}

.table-report {
  table, th, td {
    border: 1px solid $table-report-border-color;
    border-collapse: collapse;
  }

  table {
    & > tr:last-child {
      td {
        font-weight: 500;
      }
    }
  }

  th {
    background: #f3f3f3;
    font-weight: 500 !important;
    padding: 8px !important;
  }

  th, td {
    padding: 5px;
    font-size: 10.6px;
    font-weight: 400;
    width: 100px;
  }

  th.grow, td.grow {
    width: 180px;
    min-width: 180px;
  }

  th.grow, td.grow {
    width: 180px;
    min-width: 180px;
  }
  tbody {
    tr {
      td {
        text-align: center;
        padding-top: 1px;
        padding-bottom: 1px;
        padding-right: 1px;

        &:nth-child(1) {
          text-align: left;
          padding-left: 6px;
          padding-right: 6px;
        }
      }
      &:hover {
        background-color: #fff6d9;
        transition: .1s background-color;
        cursor: pointer;
        & > td {
          font-weight: 500;
        }
      }
    }
  }

  :deep(td button:focus) {
    background: none !important;
  }
  .bg-level-1 {
    background: rgba(226, 226, 226, .7);
  }
  .bg-level-2 {
    background: rgba(226, 226, 226, .4);
  }
  .bg-level-3 {
    background: rgba(226, 226, 226, .2);
  }
  .bg-level-4 {
    background: rgba(226, 226, 226, .1);
  }

}

.document-separator {
  page-break-before: always;
  margin-top: 0;
  padding-top: 20px;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.table-separator {
  page-break-inside: avoid;
  page-break-after: auto;
  margin-bottom: 20px;
}

.form-table-custom.with-padding {
  th, td {
    padding: 2px 4px !important;
  }

  thead {
    th {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
}

.form-table-custom {
  table {
    border-collapse: collapse;
  }

  thead {
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 2px 2px 0px rgb(0 0 0 / 8%),
    0 1px 5px 0px rgb(0 0 0 / 6%)
  }

  th {
    border: 1px solid $form-table-header-border-color;
    border-collapse: collapse;
  }

  td {
    border-bottom: 1px solid $form-table-border-color;
    border-collapse: collapse;

    &:first-child {
      border-left: 1px solid $form-table-border-color;
    }

    &:last-child {
      border-right: 1px solid $form-table-border-color;
    }
  }

  th {
    background: rgba(243, 243, 243, 0.5);
    font-weight: 500 !important;
  }

  th, td {
    padding: 5px;
    font-size: 13px;
    font-weight: 400;
    width: 140px;
  }

  tr {
    &:hover {
      td {
        background-color: rgba(47, 130, 50, 0.04);
      }
    }
  }

  td {
    height: 36px;
    padding: 0 !important;

    .fill-select-table-input {
      .v-select__selection.v-select__selection--comma {
        font-size: 13px !important;
      }

      .v-icon.notranslate.v-icon--link.mdi.mdi-close.theme--light {
        font-size: 20px !important;
      }
    }

    .input-error {
      background-color: #ffd6d6;

      &:focus {
        border: 1px solid #f34e46 !important;
      }
    }

    & > input {
      text-align: center;
      padding: 6px;
      outline: none;
      width: 100%;
      height: 100%;
      color: #727272;

      &:focus {
        border: 1px solid #66c46a;
      }
    }
  }

  th.grow, td.grow {
    width: 180px;
    min-width: 180px;
  }

  tbody {
    tr {
      td {
        text-align: center;

        &:nth-child(1) {
          text-align: left;
          padding-left: 14px;
        }
      }
    }
  }

  .table-button {
    position: absolute;
    left: 7px;
    top: 50%;
    transform: translateY(-50%);
  }

  .table-footer-panel {
    margin-top: 36px;
    display: flex;
  }
}

.v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 28px !important;

  .v-data-table__mobile-row__header {
    font-weight: 500 !important;
    font-size: .84rem !important;
  }

  .v-data-table__mobile-row__cell {
    font-size: .86rem !important;
  }
}

.accounting-document {
  .block-divider {
    width: 99%;
    border-bottom: 1px solid #cecece;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 7px;
    line-height: 2rem;
    color: var(--v-success-base);
    font-weight: 500;
    font-size: .84rem;
  }

  .document-disabled {
    pointer-events: none;
    user-select: none;
    opacity: .7;
  }

  .document-toolbar-title {
    font-size: 1.14rem !important;
  }

  .document-header {
    padding: 5px;
    background-color: #ffffff;

    .document-info-box {
      flex: 0 0 310px;
      border-radius: 6px;

      .document-info-box-element {
        margin-bottom: 12px;

        .info-box-element-title {
          font-size: .88rem;
          font-weight: 500;
          color: #3ba13f;
        }

        .info-box-element-divider {
          margin: 2px 0 4px 0;
          width: 100%;
          background-color: #a2a2a2;
          height: 1px;
        }

        .info-box-element-item-wrapper {
          display: flex;
          justify-content: space-between;

          .info-box-element-item {
            flex: 50%;

            .element-item-title {
              font-size: .86rem;
              color: #464646;
            }

            .element-item-value {
              font-size: .85rem;
              font-weight: 500;

              span {
                font-weight: 400;
              }
            }
          }
        }
      }

    }

    .document-header-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .document-body {
    background-color: #ffffff;
    padding-bottom: 12px;

    &:deep(.v-tabs-bar) {
      height: 32px !important;
    }

    &:deep(.v-tab.v-tab--active) {
      background-color: rgba(226, 226, 226, 0.31);
      //color: #3e3e3e;
    }

    &:deep(.v-tabs) {
      .v-tabs-slider-wrapper {
        height: 2px !important;
      }

      .v-tabs-slider {
        background-color: var(--v-success-base);
      }
    }
  }
}

canvas {
  image-rendering: pixelated;
}

.prepend-list-item {
  width: 100%;
  font-size: 0.87rem;
  position: sticky;
  top: 0;
  left: 0;
  background: white;
  z-index: 1;
  padding: 5px 16px 10px 16px;
  margin-bottom: 5px !important;
  border-bottom: 1px solid #d4d4d4;

  .prepend-list-item-buttons {
    display: flex;
    align-items: center;

    .a-list {
      display: block;
      flex: 0 0 80%;

      a {
        text-decoration: none;
        font-weight: 500;
        color: #404040;
      }
    }

    .a-button {
      display: block;
      flex: 1;
      text-align: right;
    }
  }
}

.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 46px !important;
}

i.v-icon.notranslate.mdi.mdi-lock-outline.theme--light {
  color: var(--v-secondary-base) !important;
}

.v-input input {
  max-height: 28px !important;
}

.v-text-field--filled.v-input--dense .v-label, .v-text-field--full-width.v-input--dense .v-label {
  top: 20px !important;
}

.v-text-field--filled .v-label, .v-text-field--full-width .v-label {
  top: 15px !important;
}

.v-text-field--filled.v-input--dense .v-label, .v-text-field--full-width.v-input--dense .v-label {
  top: 17px !important;
}

.v-text-field--filled:not(.v-text-field--single-line) input {
  margin-top: 20px !important;
}

.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__selections {
  padding-top: 0 !important;
}

.v-select.v-text-field--enclosed:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__selection--comma {
  margin: 22px 4px 3px 0 !important;
}

.v-select.v-text-field--enclosed:not(.v-text-field--is-booted) .v-select__selection--comma {
  margin: 8px 4px 3px 0 !important;
}

.dense-wrapper {
  & > div:not(.section-title) {
    padding-top: 2px !important;
    padding-bottom: 12px !important;
  }
}

.small-vuetify-input {
  input {
    margin-top: 2px !important;
  }
}

.custom-tabs {

  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #fafafa !important;
    height: 36px !important;
  }

  :deep(.v-window.v-item-group) {
    height: 100%;
  }
}

.custom-tabs-transparent {

  .v-window.v-item-group.theme--light {
    background-color: transparent;
  }

  div[role="tablist"] {
    background-color: transparent !important;
    height: 36px !important;
  }

  .v-window.v-item-group {
    height: calc(100% - 36px);
  }
}

.custom-tabs {

  .v-window.v-item-group.theme--light {
    background-color: transparent;
  }

  div[role="tablist"] {
    background-color: #fafafa !important;
    height: 36px !important;
  }

  .v-window.v-item-group {
    height: calc(100% - 36px);
  }
}

.v-list.v-select-list {
  outline: none !important;
}

.req-star {
  height: 100%;
  position: relative;

  .v-input__control {
    position: relative;

    &::before {
      content: "";
      background-color: #d32f2f96;
      width: 4px;
      height: 100%;
      position: absolute;
      display: inline-flex;
      left: 0;
      top: 0;
    }
  }
}

.print-page-component {
  .header-underline {
    border-bottom: 3px solid #797979;
  }

  .wr {
    .column-wrapper {
      display: flex;
      flex-direction: row;

      .left-column {
        flex: 0 1 300px;
        background-color: #ffffff;
      }

      .right-column {
        flex: 1;
      }
    }
  }

  .wr {
    height: 100vh;
  }

  .wrapper-page {
    background-color: rgb(204, 204, 204);
    width: 100%;
    height: calc(100vh - 64px);
    border: 1px solid transparent;
    overflow: scroll;
    padding: 5px;
  }

  .page {
    background-color: white;
    margin: 5mm auto;
    padding: 4mm;
  }

  .page-a4 {
    width: 210mm;
    //width: 206mm;
    min-height: 284mm;
    overflow: auto;
  }

  .page-a4-auto {
    width: 206mm;
    height: 286mm;
    overflow: auto;
  }

  .page-a4-landscape-auto {
    width: 1360px;
    min-height: 209.8mm;
    height: auto;
    overflow: auto;
  }

  .page-a4-portrait-auto {
    width: auto;
    height: 286mm;
    overflow: auto;
    zoom: 96;
  }

  .rp-text-center {
    text-align: center;
  }

  .rp-text-right {
    text-align: right;
  }

  .rp-text-left {
    text-align: right;
  }

  .rp-font-weight-medium {
    font-weight: 600 !important;
  }

  .rp-pa-2 {
    padding: 2px;
  }

  .rp-pa-4 {
    padding: 4px;
  }

  .rp-pa-6 {
    padding: 6px;
  }

  .rp-pa-8 {
    padding: 8px;
  }

  .rp-pa-10 {
    padding: 10px;
  }

  .rp-pa-12 {
    padding: 12px;
  }

  .rp-pa-14 {
    padding: 14px;
  }

  .report-section, .report-table-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    font-size: 12px;
  }

  .report-section-column {
    width: 100%;
  }

  .report-table {
    width: 100%;
    text-align: center;
  }

  .report-table.report-table-border {
    .report-table-col {
      border-left: 1px solid $border-color-main;
      border-top: 1px solid $border-color-main;
    }

    .report-table-col.group {
      border-left: none;
      border-top: none;
    }
  }

  .report-table-row {
    width: 100%;
    min-height: 30px;
  }

  .table-col-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center
  }

  .table-col-down {
    align-items: flex-end !important;
  }

  .table-col-content-right {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-end;
  }

  .table-col-content-left {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: flex-start;
  }

  .rp-row {
    width: 100%;
    padding: 2px;
  }

  .report-empty-row {
    width: 100%;
    height: 4px;
  }

  .rp-col-1 {
    flex: 0 1 8.33%;
  }

  .rp-col-2 {
    flex: 0 1 16.66%;
  }

  .rp-col-3 {
    flex: 0 1 25%;
  }

  .rp-col-4 {
    flex: 0 1 33.33%;
  }

  .rp-col-5 {
    flex: 0 1 41.65%;
  }

  .rp-col-6 {
    flex: 0 1 50%;
  }

  .rp-col-7 {
    flex: 0 1 58.31%;
  }

  .rp-col-8 {
    flex: 0 1 66.64%;
  }

  .rp-col-9 {
    flex: 0 1 75%;
  }

  .rp-col-10 {
    flex: 0 1 83.33%;
  }

  .rp-col-11 {
    flex: 0 1 91.67%;
  }

  .rp-col-12 {
    flex: 0 1 100%;
  }

  .rp-col-border {
    border: 1px solid grey;
  }
}

@media print {
  .hide-before-print-cab {
    display: block !important;
  }
  div[role='dialog'] {
    //.v-dialog {
    display: inline !important;
    position: static !important;
    float: none !important;

    .v-dialog {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .v-dialog__content {
    display: block;

    .v-dialog--fullscreen {
      position: relative;
    }
  }
  .v-overlay {
    display: none !important;
    visibility: hidden !important;
  }

  .print-page-component {
    .page {
      padding: 0 4mm !important;
      margin: 0 !important;
      zoom: 1 !important;
    }
  }

  .report {
    transform: scale(1) !important;
    width: auto !important;
    transform-origin: initial !important;
  }


  @page {
    margin: 4mm 0 6mm 0 !important;
    @bottom-center {
      content: "Page " counter(page) " of " counter(pages);
    }
  }

  .page-a4-landscape-auto {
    height: 209.8mm !important;
  }

  .v-dialog--fullscreen {
    height: auto;
    overflow: visible;
    position: absolute;
    box-shadow: none;
    border: none;
  }

  .v-dialog__content {
    position: absolute;
    box-shadow: none;
    border: none;
  }

  .v-main {
    padding: 0 !important;
    margin: 0 !important;
    height: auto !important;
    width: auto !important;
    background-color: #ffffff !important;

    .v-main__wrap {
      padding: 0 !important;
      margin: 0 !important;
      height: auto !important;
      width: auto !important;
      background-color: #ffffff !important;
    }
  }

  .non-printable {
    display: none !important;
    visibility: hidden !important;
  }

  .print-page-component {
    .page-separator {
      page-break-after: always !important;
      page-break-inside: avoid !important;
      page-break-before: avoid !important;
    }

    .page {
      //margin: 1px 1px 4px 1px !important;
      overflow: visible !important;
      margin-bottom: 5mm;
    }

    .wr {
      height: auto !important;
      overflow: visible !important;
    }

    .wrapper-page {
      height: auto !important;
      overflow: visible !important;
      background-color: white !important;
      box-shadow: none !important;
      border: none !important;
      margin: 0 !important;
    }
  }

  .regular-report-print-page {
    .custom-tabs div[role=tablist] {
      display: none !important;
    }
    .v-window-item {
      height: auto !important;
      overflow: hidden !important;
      padding-left: 8px !important;
    }
    input {
      background-color:#ffffff !important;
    }

    table {
      border-color: #000000 !important;

      td {
        line-height: 14px !important;
      }

      td {
        input {
          border: none !important;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.custom-table.small {
  .v-data-table__wrapper {
    table {
      tbody {
        tr {
          td {
            font-size: .82rem !important;
          }
        }
      }
    }
  }
}

.custom-table.custom-table-full {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 2px;
  padding-right: 2px;

  .v-data-table__wrapper {
    flex: 1;
  }

  .v-data-footer {
    flex: 0;
  }
}

.custom-table.custom-table-full.slim {
  .v-data-table__wrapper {
    thead {
      tr {
        th {
          line-height: .98rem !important;
          padding-top: 4px;
          padding-bottom: 3px;
        }
      }
    }
  }
}

.custom-table.event-logger-table {
  .v-data-table__wrapper {
    tbody {
      tr {
        td {
          font-size: .84rem !important;
          height: 36px !important;
        }
      }
    }
  }
}

.custom-table {
  .v-data-table__wrapper {
    th[role="columnheader"] {
      background-color: #f6f6f6;
      font-size: .8rem;
    }

    tbody {
      tr {
        cursor: pointer;

        td {
          height: 30px !important;
          &:has(.no-padding) {
            padding-right: 0 !important;
          }
        }

        &:last-child {
          td {
            //border-bottom: 1px solid #e0e0e0 !important;
          }
        }
      }
    }

    thead {
      tr {
        th {
          height: 38px !important;
        }
      }
    }
  }

  .v-data-table__empty-wrapper {
    td {
      text-transform: uppercase;
      padding-top: 40px !important;
      padding-bottom: 40px !important;
      font-size: 1.1em !important;
      font-weight: 500;
      color: #3f9043d1;
    }
  }
}

.custom-tabs {
  color: inherit;
}

.document-small-tabs {
  .v-tabs-bar {
    height: 32px !important;

  }

  .v-tab {
    font-size: .8rem !important;
  }
}

.no-data-text {
  display: block;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  padding-top: 40px !important;
  padding-bottom: 40px !important;
  font-size: 1.1em !important;
  font-weight: 500;
  color: #3f9043d1;
}

.theme--light.v-text-field:not(.v-input--has-state):hover > .v-input__control > .v-input__slot:before {
  border-color: grey !important;
}

.button-accept {
  background-color: #f18a0d1a;
  color: #d17000 !important;
  caret-color: #d17000 !important;
}

.button-accept-1 {
  background-color: #f18a0d1a;
  color: #262626 !important;
  caret-color: #d17000 !important;
}


.custom-text-field {
  &:deep(label) {
    color: var(--v-success-darken1) !important;
  }

  label {
    color: var(--v-success-darken1) !important;
  }

  .v-select__selection.v-select__selection--comma.v-select__selection--disabled {
    color: #2d2d2d;
  }
}

.theme--light.custom-text-field.error-cs {
  & > .v-input__control > .v-input__slot::before {
    border-color: var(--v-error-base) !important;
  }

  &:deep(label) {
    color: var(--v-error-base) !important;
  }

  label {
    color: var(--v-error-base) !important;
  }

  .v-select__selection.v-select__selection--comma.v-select__selection--disabled {
    color: #2d2d2d;
  }
}

.document-input {
  width: 100%;

  &.disabled {
    cursor: pointer !important;

    .label {
      color: rgba(121, 119, 119, 0.77) !important;
    }

    input, textarea {
      cursor: pointer !important;
      opacity: .8;
      border-bottom: 1px solid #969696;
      background-color: rgba(229, 229, 229, 0.52);

      &:active {
        opacity: .8;
        border-color: #969696;
        transition: all 30ms ease;
      }

      &:hover {
        border-color: #969696;
        opacity: .8;
        transition: all 30ms ease;
      }

      &:focus {
        opacity: .8;
        border-color: #969696;
        font-weight: 500;
      }
    }
  }

  &.small {
    input, textarea {
      padding: 2px 3px;
      font-size: .79rem;
      line-height: 1.04rem;
    }
  }

  .label {
    font-size: .84rem;
    line-height: .9rem;
    word-break: keep-all;
    padding-left: 1px;
    margin-bottom: 4px !important;
    color: rgba(69, 69, 69, 0.77);
  }

  .label-error {
    color: #dc5d56 !important;
  }

  input, textarea {
    outline: none;
    border: none;
    opacity: .76;
    padding: 3px 4px;
    font-size: .89rem;
    transition: all 30ms ease;
    border-bottom: 1px solid #6e6e6e;
    background-color: rgba(229, 229, 229, 0.51);
    border-top-left-radius: 5px;
    width: 100%;

    &:active {
      opacity: 1;
      border-color: var(--v-success-base);
      transition: all 30ms ease;
    }

    &:hover {
      border-color: var(--v-success-base);
      opacity: 1;
      transition: all 30ms ease;
    }

    &:focus {
      opacity: 1;
      border-color: var(--v-success-base);
      font-weight: 500;
    }
  }

  .input-select-icon {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
  }

  .input-select-icon-left {
    position: absolute;
    right: 46px;
    top: 50%;
    transform: translateY(-50%);
  }

  .input-icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .input-clear-icon {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-53%);
    cursor: pointer;
  }

  .error-input {
    border-bottom: 1px solid #dc5d56;
    transition: all 30ms ease;

    &:active {
      opacity: 1;
      border-color: #dc5d56 !important;
      transition: all 30ms ease;
    }

    &:hover {
      border-color: #dc5d56 !important;
      opacity: 1;
      transition: all 30ms ease;
    }

    &:focus {
      opacity: 1;
      border-color: #dc5d56 !important;
      font-weight: 500;
    }
  }
}
.document-input.date-input-filled {
  border-radius: 4px 4px 0 0;
  background-color: rgba(0, 0, 0, 0.06);
  //padding: 0 12px;
  min-height: 48px;

  .label {
    position: absolute;
    top: 8px;
    padding: 0 12px;
    font-size: .76rem;
  }
  .not-label {
    position: absolute !important;
    bottom: -1px;
    width: 100%;

    input {
      padding: 0 12px 2px;
      background-color: transparent !important;
      line-height: 18px;
      font-size: 15px;
    }

    .input-icon {
      //top: 50% !important;
      transform: translateY(-60%) !important;
      right: 12px !important;
    }
  }
}

.document-input.input-filled{
  border-radius: 4px 4px 0 0;
  background-color: rgba(0, 0, 0, 0.06);
  //padding: 0 12px;
  min-height: 48px;
  position: relative;

  &:hover {
    background-color: #E0E0E0;

    .not-label {
      .select-input-buttons {
        background-color: #E0E0E0 !important;
      }
    }
  }

  .label:not(.label-middle) {
    position: absolute;
    top: 8px;
    padding: 0 12px;
    font-size: .76rem;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .label.label-middle {
    position: absolute;
    top: 18px;
    padding: 0 12px;
    font-size: 1rem;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .not-label {
    top: 20px;

    input {
      padding: 0 12px 2px;
      background-color: transparent !important;
      line-height: 18px;
      font-size: 15px;

      &:focus {
        border-color: #626262 !important;
      }
      &:hover {
        border-color: #626262 !important;
      }
    }

    .select-input-buttons {
      background-color: #f0f0f0 !important;
      top: -11px !important;
    }

    //.input-icon {
    //  //top: 50% !important;
    //  transform: translateY(-60%) !important;
    //  right: 12px !important;
    //}
  }
}

.document-input.date-input-filled.req-star,
.document-input.input-filled.req-star{
  &:before {
    content: "";
    background-color: #d32f2f96;
    width: 4px;
    height: 100%;
    position: absolute;
    display: inline-flex;
    left: 0;
    top: 0;
  }
}


.document-input.date-input-button {
  min-height: 36px;
  padding: 0 16px;
  background-color: #eeeeee !important;
  border-color: #eeeeee !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  min-width: 180px !important;
  width: auto !important;
  text-transform: uppercase;
  font-size: 0.9rem !important;
  font-weight: 500 !important;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-indent: 0.0892857143em;
  vertical-align: middle;
  display: flex;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-label {
    margin-right: 10px;
    //margin-top: 4px;
    text-wrap: nowrap;
  }

  input {
    line-height: 1rem;
    margin-bottom: 2px !important;
    background-color: transparent !important;
  }

  .input-icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.small-checkbox {
  .v-label {
    font-size: 14px !important;
    line-height: 16px !important;
  }
}

.table-without-lines {
  width: 100%;

  .table-row {
    display: flex;
    width: 100%;
  }

  .table-col {
    padding: 4px;
    font-size: 0.82rem;
    line-height: 1.05rem;
    //white-space: break-spaces;
    font-weight: normal;
    background-color: #f8f8f8;
    overflow: hidden;
    word-wrap: break-word;
  }

  .table-header {
    display: flex;

    .table-col {
      padding: 6px 4px;
      background-color: rgba(227, 227, 227, 0.64);
      font-weight: 500;
      font-size: 0.8rem;
      line-height: 0.92rem;
      word-wrap: break-word;
      overflow: hidden;
      //white-space: normal;
      //white-space: break-spaces;
    }
  }
}


.opening-1 {
  color: #c46d37;
}

.opening-2 {
  color: #d32f2f;
}

.preopening-1 {
  color: var(--v-primary-base);
}

.holiday-1 {
  color: #009688;
}

.color-saturday {
  color: var(--v-secondary-base);
}

.color-sunday {
  color: var(--v-error-base);
}

.color-monday {
  color: var(--v-primary-base);
}

.color-tuesday {
  color: var(--v-primary-base);
}

.color-wednesday {
  color: var(--v-primary-base);
}

.color-thursday {
  color: var(--v-primary-base);
}

.color-friday {
  color: var(--v-primary-base);
}

.custom-badge {
  .v-badge__wrapper {
    .v-badge__badge {
      height: 16px !important;
      min-width: 16px !important;
      font-size: .67rem !important;
      padding: 4px 2px !important;
    }
  }
}

.sm-table {
  .table-archive-row {
    .table-col {
      flex: 1 1 50%;
      border-left: none;

      &:nth-child(1) {
        border-top: .5px solid $border-archive-color;
      }

      &:nth-child(2) {
        border-top: .5px solid $border-archive-color;
      }

      &:nth-child(odd) {
        border-left: .5px solid $border-archive-color;
      }

      .col-header {
        flex: 0 0 40%;
      }

      .col-value {
        flex: 0 0 60%;
      }
    }
  }
}

.table-archive-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;

  .table-col {
    flex: 1 1 100%;
    display: flex;
    border-bottom: .5px solid $border-archive-color;
    border-left: .5px solid $border-archive-color;
    border-right: .5px solid $border-archive-color;
    padding: 2px;
    line-height: 1.2rem;

    &:nth-child(1) {
      border-top: .5px solid $border-archive-color;
    }

    .col-header {
      flex: 0 0 50%;
      font-size: 0.75rem;
      text-align: left;
      padding-left: 20px;
      color: #2d2d2d;
      font-weight: 500;
    }

    .col-value {
      flex: 0 0 50%;
      font-size: 13px;
      font-weight: normal;
      //color: #626262;
      padding-right: 3px;
    }
  }

}


//ACCOUNTING DOCUMENTS
.accounting-document {
  $accounting-border-color: #e8e8e8;
  $accounting-header-color: #fffdfd;
  $accounting-body-hover-color: #fff9e2;
  $accounting-header-hover-color: #fdfdfd;
  $accounting-footer-hover-color: #cecece;

  .document-check-box {
    label {
      font-size: .84rem !important;
    }
  }

  .block-divider {
    width: 99%;
    border-bottom: 1px solid #cecece;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 7px;
    line-height: 2rem;
    color: var(--v-success-base);
    font-weight: 500;
    font-size: .84rem;
  }

  .document-header {
    padding: 5px;
    background-color: #ffffff;

    .document-header-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .document-body {
    background-color: #ffffff;
    padding-bottom: 12px;

    &:deep(.v-tabs-bar) {
      height: 32px !important;
    }

    .v-tabs-bar {
      height: 32px !important;
    }

    &:deep(.v-tab.v-tab--active) {
      background-color: rgba(226, 226, 226, 0.31);
      //color: #3e3e3e;
    }

    .v-tab.v-tab--active {
      background-color: rgba(226, 226, 226, 0.31);
    }

    &:deep(.v-tabs) {
      .v-tabs-slider-wrapper {
        height: 2px !important;
      }

      .v-tabs-slider {
        background-color: var(--v-success-base);
      }
    }

    .v-tabs {
      .v-tabs-slider-wrapper {
        height: 2px !important;
      }

      .v-tabs-slider {
        background-color: var(--v-success-base);
      }
    }
  }

  .custom-tabs.without {
    :deep(div[role='tablist']) {
      background-color: transparent !important;
    }

    div[role='tablist'] {
      background-color: transparent !important;
    }
  }


  .document-body-row {
    display: flex;
    flex-wrap: wrap;
  }

  .document-table {
    margin-top: 16px;
    font-size: .74rem;
    color: #2d2d2d;
    height: calc(100% - 6px);

    .document-table-row {
      display: flex;
      flex-wrap: nowrap;
      flex-basis: 100px;
    }

    .document-table-row > .document-table-col {
      border-top: 1px solid $accounting-border-color;
    }

    .document-table-col-group {
      display: flex;
      flex-direction: column;

      .document-table-col {
        border-top: 1px solid $accounting-border-color;
        flex: 1;

        &:nth-child(1) {
          border-left: none;
          border-bottom: none;
        }
      }
    }

    .document-table-col {
      background-color: white;
      border-right: 1px solid $accounting-border-color;
      border-bottom: 1px solid $accounting-border-color;
      padding: 0 4px;
      align-items: center;
      min-height: 28px;

      &:nth-child(1) {
        border-left: 1px solid $accounting-border-color;
      }
    }


    .document-table-header {
      margin-bottom: 8px;
      font-size: .78rem;
      position: sticky;
      top: 0;
      z-index: 50;

      .document-table-row {
        .document-table-col {
          background-color: $accounting-header-color;
          color: #696969;
          font-weight: 500;
          line-height: 1.04rem !important;
          padding-top: 6px !important;
          padding-bottom: 6px !important;
        }

        .document-table-col.one-row {
          background-color: $accounting-header-color;
          color: #696969;
          font-weight: 500;
          padding: 4px;
          min-height: 40px;
        }
      }
    }

    .document-table-body {
      height: 100%;
      overflow: auto;
      position: relative;

      .document-table-header {
        .document-table-row {
          &:hover {
            .document-table-col {
              background-color: $accounting-header-hover-color;
            }
          }
        }
      }

      .document-table-footer {
        .document-table-row {
          &:hover {
            .document-table-col {
              background-color: $accounting-footer-hover-color;
            }
          }
        }
      }


      .document-table-row {
        margin-bottom: 3px;
        transition: background-color .2s ease-in-out;

        &:hover {
          .document-table-col {
            background-color: $accounting-body-hover-color;
          }
        }

        .document-table-col {
          padding-top: 2px;
          padding-bottom: 2px;
          line-height: 1.7rem;
        }

        .document-table-col.one-row {
          padding: 4px;
        }

        .document-table-col.one-row.result {
          padding-left: 8px;
          font-size: .9rem;
          font-weight: 400;
        }
      }
    }

    .document-table-footer {
      height: 36px;
      position: sticky;
      bottom: 7px;

      .document-table-row {
        font-size: .9rem;
        font-weight: 500;

        .document-table-col {
          background-color: #ffffff;
          color: #4b4b4b;
        }
      }
    }
  }

  .document-table.with-command {
    height: calc(100% - 34px);
  }

  .command-panel {
    height: 29px;
  }
}

.accounting-document.pretty {
  $accounting-border-color: #e8e8e8;
  $accounting-header-color: #fffdfd;
  $accounting-body-hover-color: #fff9e2;
  $accounting-header-hover-color: #fdfdfd;
  $accounting-footer-hover-color: #cecece;

  .document-table-header {
    .document-table-row {
      .document-table-col {
        padding: 6px;
        line-height: 1.4rem !important;
        background-color: #f2f2f2;

        &:nth-child(1) {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  .document-row-body {
    .document-table-row {
      .document-table-col {
        padding: 6px;
        line-height: 1.4rem !important;

        &:nth-child(1) {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }

  .document-table-footer {
    margin-top: 12px !important;

    .document-table-row {
      .document-table-col {
        padding: 6px;
        line-height: 1.4rem !important;
        font-size: 0.78rem;
        background-color: #f2f2f2;

        &:nth-child(1) {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }
        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }
      }
    }
  }
}

//PAY TABLE WRAPPER
.pay-table-wrapper {
  .v-select__selections {
    line-height: 14px !important;
  }

  .table-no-data {
    padding: 55px 20px;
    text-transform: uppercase;
    color: var(--v-success-darken1);
    text-align: center;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .pay-table {
    font-size: .81rem;

    .editable {
      padding: 8px 2px !important;
    }

    .expand-icon:focus:after {
      opacity: 0 !important;
    }

    .error-input {
      input {
        border-bottom: var(--pt-border-width) solid var(--pt-error-color) !important;
      }
    }

    .error-input.table-input {
      .v-autocomplete {
        .v-input__control {
          .v-select__slot {
            border-bottom: var(--pt-border-width) solid var(--pt-error-color) !important;

            input::placeholder {
              color: var(--v-error-base);
            }
          }
        }
      }
    }

    input.error-input {
      border-bottom: var(--pt-border-width) solid var(--pt-error-color) !important;
      //padding-bottom: 0 !important;
    }

    .pay-table-col {
      padding: 8px 2px;
      display: flex;
      flex-wrap: nowrap;
      align-self: center;
      //min-height: 40px;

      & > span {
        display: block;
        align-self: center;
      }

      //align-self: center;
      //align-items: center;
    }


    .pay-table-col.small {
      padding: 1px 2px !important;
      display: flex;
      flex-wrap: nowrap;
      align-self: center;

      & > span {
        display: block;
        align-self: center;
      }
    }

    .pay-table-row, .pay-table-row-expand-header, .pay-table-expand-body-row {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      //align-items: center;

      &:hover {
        background-color: #f3f3f3;
      }
    }

    .pay-table-expand-body-row {
      .pay-table-col {
        padding-top: 8px !important;
      }
    }

    .pay-table-row.expandable {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      transition: height 600ms ease-in, height 600ms ease-out;
    }

    .pay-table-header {
      font-size: 0.74rem;
      font-weight: 500;
      color: #454545;
      background-color: #f0f0f07a;

      .pay-table-row {
        border-bottom: thin solid var(--pt-border-row-color);

        .pay-table-col {
          padding: 10px 2px !important;

          &:nth-child(1) {
            padding-left: 16px !important;
            border-left: none;
          }
        }

        .pay-table-col.small {
          padding: 4px 2px !important;

          &:nth-child(1) {
            padding-left: 12px !important;
            border-left: none;
          }
        }
      }
    }

    .pay-table-body {
      .pay-table-row-expand-body {
        display: none;
        max-height: 0;
      }

      .pay-table-row-expand-body.expanded {
        display: block;
        max-height: 100%;
      }

      .pay-table-row.expanded {
        padding-bottom: 8px !important;
        border-bottom: 4px solid #eeeeee
      }

      .pay-table-row {
        border-bottom: thin solid var(--pt-border-row-color);

        .not-actual {
          input:not([type="hidden"]) {
            color: var(--v-error-base) !important;
            font-weight: bold;
          }
        }

        input:not([type="hidden"]) {
          width: 84%;
          display: block;
          outline: none;
          border-bottom: var(--pt-border-width) solid var(--pt-border-color);
          padding: 3px 4px 4px 0;
          transition: all .2s ease-in-out;
          line-height: 0 !important;
          font-size: .78rem !important;

          &:focus {
            border-color: var(--v-success-base);
            font-weight: 500;
          }
        }

        .input-autocomplete {
          width: 84%;
          margin-top: 0 !important;
          font-size: .8rem !important;
          border: none !important;

          .v-input__control .v-input__slot:before {
            border-bottom: var(--pt-border-width) solid var(--pt-border-color) !important;
          }

          .v-input__control .v-input__slot:after {
            border-bottom: var(--pt-border-width) solid !important;
            border-color: var(--v-success-base);
          }
        }
      }
    }

    .pay-table-footer {
      font-size: 0.8rem;
      font-weight: 600;
      color: #454545;
      background-color: #f0f0f07a;
      border-top: 1px solid rgba(240, 240, 240, 0.47);
    }


    .select-checkbox {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }

    .table-input {
      width: 84%;
      display: block;
      outline: none;
      padding: 0;
      margin: 0;

      //&:focus {
      //  border-color: var(--v-success-base);
      //  font-weight: 500;
      //}
      //input {
      //  font-size: .8rem !important;
      //  padding: 2px 4px 2px 0 !important;
      //  transition: all .2s ease-in-out !important;
      //  line-height: 0 !important;
      //}

      .v-autocomplete {
        .v-input__control {
          .v-select__slot {
            border-bottom: var(--pt-border-width) solid var(--pt-border-color);
            padding-bottom: 2px;
            //padding: 3px 4px 4px 0;
            //transition: all .2s ease-in-out;
            //line-height: 0 !important;
            font-size: .78rem !important;
          }
        }
      }

      input {
        border-bottom: none !important;
      }

      .v-input__control {
        margin-top: 0 !important;
        //border-bottom: 2px solid $border-color;

        .v-input__slot:before {
          border: none !important;
        }

        .v-input__slot:after {
          border: none !important;
        }

        .v-input__append-inner {
          display: none !important;
        }

        .v-select__selection {
          input {
            border-bottom: none !important;
            font-size: 1rem;
          }
        }

        .v-select__selections {
          border-bottom: var(--pt-border-width) solid var(--pt-border-color);
        }

        .v-select__selection.v-select__selection--comma {
          margin: 0 !important;
          font-size: .78rem !important;
        }
      }
    }

    .error-input {
      .v-input__control {
        border-color: var(--pt-error-color) !important;
      }
    }

    .negative-balance {
      color: #ff1111;
      font-weight: 500;
    }

    .positive-balance {
      color: #2a9d2f;
      font-weight: 500;
    }

    .equal-balance {
      font-weight: 500;
    }
  }
}
.pay-table-wrapper-full {
  .pay-table {
    .pay-table-header {
      height: 34px;
    }
    .pay-table-body {
      overflow: auto;
      padding-bottom: 20px
    }
  }
}

//REPORTS
.general-report-by-flat {
  table {
    tbody.details {
      tr {
        &:hover {
          background-color: #f8f7f2;
        }
      }
    }
    tbody.undefined {
      tr {
        &:hover {
          background-color: #f8f7f2;
        }
      }
    }
  }
}

.report-component-wrapper {

  table:not(.custom) {
    tbody.details {
      tr {
        &:hover {
          background-color: #f8f7f2;
        }
      }
    }
    tbody.undefined {
      tr {
        &:hover {
          background-color: #f8f7f2;
        }
      }
    }
  }

  table.transparent-header {
    border: none !important;
    background-color: transparent !important;

    thead {
      border: none !important;
      background-color: transparent !important;

      tr {
        border: none !important;
        background-color: transparent !important;

        th {
          background-color: transparent !important;
          border: none !important;
        }
      }
    }
  }

  .page-a4-landscape-auto {
    width: 1160px !important;
    position: relative;
  }

  .page-a4-landscape-auto-full {
    width: auto !important;
    position: relative;
    overflow: auto;
  }

  table:not(.custom), th:not(.custom), td:not(.custom) {
    border: 1px solid #cbcbcb;
    border-collapse: collapse;
  }

  th:not(.custom) {
    background: #f3f3f3;
    font-weight: 500 !important;
  }

  th:not(.custom), td:not(.custom) {
    padding: 5px;
    font-size: 10.6px;
    font-weight: 400;
    width: 100px;
  }

  th.grow, td.grow {
    width: 180px;
    min-width: 180px;
  }

  tbody:not(.custom) {
    tr {
      td {
        text-align: center;

        &:nth-child(1) {
          text-align: left;
          padding-left: 14px;
        }
      }
    }
  }
  :deep(td button:focus) {
    background: none !important;
  }
  .bg-level-0 {
    background: #e2e2e2;
  }
  .bg-level-1 {
    background: #f4f4f4;
  }
  .bg-level-2 {
    background: #f6f6f6;
  }
  .bg-level-3 {
    background: #f8f8f8;
  }
}

.user-message-carousel {
  .v-window__next {
    top: calc(100% - 40px) !important;
    right: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .v-window__prev {
    top: calc(100% - 40px) !important;
    left: calc(100% - 70px) !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .carousel-pages {
    top: calc(100% - 40px) !important;
    position: absolute;
  }
}
</style>
