import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/after-login',
    name: 'afterLogin',
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/update-information',
    name: 'UpdateInformation',
    component: () => import("@/components/UpdateInformation.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/html-table',
    name: 'HtmlTable',
    component: () => import("@/components/HtmlTable"),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/debt-work-calls',
    name: 'DebtWorkCalls',
    component: () => import("@/components/DebtWorkCalls.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/appeal',
    name: 'Appeal',
    component: () => import("@/components/Appeal"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/estimate',
    name: 'Estimate',
    component: () => import("@/components/Estimate"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/voting',
    name: 'VotingNew',
    component: () => import("@/components/VotingNew"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () => import("@/components/privacy_policy"),
    meta: {
      requiresAuth: false,
      layout: 'without'
    },
  },
  {
    path: '/timesheet',
    name: 'Timesheet',
    component: () => import("@/components/accounting/personal_timesheet"),
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: () => import("@/components/Dashboard"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/personal-cabinet-desktop',
    name: 'PersonalCabinetDesktop',
    component: () => import("@/components/PersonalCabinetDesktop.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/user-event-logger',
    name: 'UserEventLogger',
    component: () => import("@/components/UserEventLogger"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/site/:organization/home',
    name: 'SiteHomePage',
    component: () => import("@/components/PersonalSite/HomePage"),
    meta: {
      requiresAuth: false,
      layout: 'site'
    },
  },
  {
    path: '/site/:organization/debt',
    name: 'SiteDebt',
    component: () => import("@/components/PersonalSite/Debt"),
    meta: {
      requiresAuth: false,
      layout: 'site'
    },
  },
  {
    path: '/site/:organization/management',
    name: 'SiteManagement',
    component: () => import("@/components/PersonalSite/Management"),
    meta: {
      requiresAuth: false,
      layout: 'site'
    },
  },
  {
    path: '/site/:organization/contacts',
    name: 'SiteContacts',
    component: () => import("@/components/PersonalSite/Contacts"),
    meta: {
      requiresAuth: false,
      layout: 'site'
    },
  },
  {
    path: '/site/:organization/documents',
    name: 'SiteDocuments',
    component: () => import("@/components/PersonalSite/Documents"),
    meta: {
      requiresAuth: false,
      layout: 'site'
    },
  },
  {
    path: '/site/:organization/gallery',
    name: 'SiteGallery',
    component: () => import("@/components/PersonalSite/Gallery"),
    meta: {
      requiresAuth: false,
      layout: 'site'
    },
  },
  {
    path: '/site/:organization/news/:news_id/view',
    name: 'SiteNewsView',
    component: () => import("@/components/PersonalSite/NewsView"),
    meta: {
      requiresAuth: false,
      layout: 'site'
    },
    params: true
  },
  {
    path: '/not-found-site',
    name: 'NotFoundSite',
    component: () => import("@/components/PersonalSite/NotFound"),
    meta: {
      requiresAuth: false,
      layout: 'without'
    },
  },
  {
    path: '/site-blocked',
    name: 'SiteBlocked',
    component: () => import("@/components/PersonalSite/SiteBlocked"),
    meta: {
      requiresAuth: false,
      layout: 'without'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import("@/views/Login"),
    meta: {
      requiresAuth: false,
      layout: 'without'
    },
  },
  {
    path: '/',
    name: 'Desktop',
    component: () => import("@/components/HousePayDesktop"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/legacy-desktop',
    name: 'LegacyDesktop',
    component: () => import("@/components/HousePayDesktopLegacy"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/legacy-document-crud',
    name: 'LegacyDocumentCrud',
    component: () => import("@/components/for_fill/LegacyDocuments"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/legacy-export-tax-bill-to-xml',
    name: 'DocumentTaxBillExportToXml',
    component: () => import("@/components/for_fill/LegacyExportTaxBillToXml.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/print-legacy-documents',
    name: 'PrintLegacyDocuments',
    component: () => import("@/components/PrintDocumentAll"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/legacy-document-settings',
    name: 'LegacyDocumentSettings',
    component: () => import("@/components/LegacyDocumentSettingsView"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/site-admin',
    name: 'SiteAdmin',
    component: () => import("@/components/SiteAdmin"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/site-profile',
    name: 'SiteProfile',
    component: () => import("@/components/SiteProfile"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/work-place/:person_hash',
    name: 'WorkPlace',
    component: () => import("@/components/HousePayWorkPlace"),
    params: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/work-place-legacy/:person_hash',
    name: 'LegacyWorkPlace',
    component: () => import("@/components/HousePayWorkplaceLegacy"),
    params: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/buildings',
    name: 'Buildings',
    component: () => import("@/components/Buildings"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/buildings/:id',
    name: 'BuildView',
    component: () => import("@/components/BuildingView"),
    params: true,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/streets',
    name: 'Streets',
    component: () => import("@/components/StreetView"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/tariff-organization',
    name: 'TariffOrganization',
    component: () => import("@/components/Tariffs"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/regulars',
    name: 'Regulars',
    component: () => import("@/components/Regulars"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/remove-downloaded-payments',
    name: 'RemoveDownloadedPayments',
    component: () => import("@/components/for_fill/RemoveDownloadedPayments"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/fill-data',
    name: 'FillData',
    component: () => import("@/components/for_fill/FillData"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/flat-counter-avg-list',
    name: 'FlatCounterAvgList',
    component: () => import("@/components/FlatCounterAvgList"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/flat-counter-fmc-list',
    name: 'FlatCounterFmcList',
    component: () => import("@/components/FlatCounterFmcList"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/change-building-tariff',
    name: 'ChangeBuildingTariff',
    component: () => import("@/components/for_fill/FillTariffByBuilding"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/import-social-query',
    name: 'ImportSocialQuery',
    component: () => import("@/components/for_fill/FillSocialQuery.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/create-pension-fund-tariff-xml-file',
    name: 'CreatePensionTariffXmlFile',
    component: () => import("@/components/for_fill/FillPensionTariffXml.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/create-flats',
    name: 'CreateFlats',
    component: () => import("@/components/for_fill/CreateFlats"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/monetary',
    name: 'Monetary',
    component: () => import("@/components/MonetaryList"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/accounting-desktop',
    name: 'AccountingDesktop',
    component: () => import("@/components/accounting/AccountingDesktop"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/accounting-report',
    name: 'AccountingReport',
    component: () => import("@/components/accounting/reports/AccountingReportPage"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/accounting/view/:page_name',
    name: 'AccountingDesktopView',
    props: true,
    component: () => import("@/components/accounting/AccountingDesktop"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/accounting-list',
    name: 'AccountingList',
    component: () => import("@/components/accounting/list_modal/list_wrapper"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/chart-of-accounts',
    name: 'ChartOfAccounts',
    component: () => import("@/components/accounting/chart_of_accounts"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/nomenclature-list',
    name: 'Nomenclature',
    component: () => import("@/components/accounting/nomenclature_list"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/nomenclature-list-select',
    name: 'NomenclatureListSelect',
    component: () => import("@/components/accounting/nomenclature_list_group"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/one-time-charge',
    name: 'OneTimeCharge',
    component: () => import("@/components/OneTimeCharge"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/removal',
    name: 'Removal',
    component: () => import("@/components/Removal"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/eps-put-file',
    name: 'EpsPutFile',
    component: () => import("@/components/EpsPutFile"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/privat-put-file',
    name: 'PrivatPutFile',
    component: () => import("@/components/PrivatPutFile"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/export-meter-reading',
    name: 'ExportMeterReading',
    component: () => import("@/components/ExportCounterReading.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/eps-import-base',
    name: 'EpsImportBase',
    component: () => import("@/components/EpsBaseImport"),
    meta: {
      requiresAuth: true,
      superUser: true
    },
  },
  {
    path: '/privilege-categories',
    name: 'PrivilegeCategories',
    component: () => import("@/components/CategoryView"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import("@/components/service/ServiceView"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/checkers',
    name: 'Checkers',
    component: () => import("@/components/CheckerView"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/user-message-history',
    name: 'UserMessageHistory',
    component: () => import("@/components/UserMessageView.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/sms-send-template',
    name: 'SmsSendTemplate',
    component: () => import("@/components/SmsSendTemplateView.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/sms-send-config',
    name: 'SmsSendConfig',
    component: () => import("@/components/SmsSendConfig.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/spreed-sheet',
    name: 'SpreedSheet',
    component: () => import("@/components/SpreedSheetView.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/counter-transfer-coefficient',
    name: 'CounterTransferCoefficient',
    component: () => import("@/components/CounterTransferCoefficientView.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/penalty-tariff',
    name: 'PenaltyTariff',
    component: () => import("@/components/PenaltyTariffView.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/service-create',
    name: 'ServiceCreate',
    component: () => import("@/components/service/ServiceCreate"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/normative-create',
    name: 'NormativeCreate',
    component: () => import("@/components/service/NormativeCreate"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/flat-import',
    name: 'FlatImport',
    component: () => import("@/components/import/FlatImport"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import("@/components/CounterHeader"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/print',
    name: 'Print',
    component: () => import("@/components/PrintPage"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/organization',
    name: 'Organization',
    component: () => import("@/components/Organization"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/dispatcher-call',
    name: 'DispatcherCall',
    component: () => import("@/components/DispatcherCallView.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: () => import("@/components/accounting/accounting_calendar"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/financial-stats',
    name: 'FinancialStats',
    component: () => import("@/components/financial_stats"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/payments/documents',
    name: 'PaymentDocuments',
    component: () => import("@/components/PaymentsList"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reading/documents',
    name: 'ReadingDocument',
    component: () => import("@/components/ReadingDocumentList"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payments/documents/:header_id',
    name: 'PaymentDocumentHeader',
    component: () => import("@/components/DocumentPayHeader"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/payments/documents-legacy/:header_id',
    name: 'PaymentDocumentHeaderLegacy',
    component: () => import("@/components/DocumentPayHeaderLegacy"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/flat-cashback/documents',
    name: 'FlatCashbackDocuments',
    component: () => import("@/components/FlatCashbackList"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/flat-cashback/documents-legacy/:header_id',
    name: 'FlatCashbackDocumentsLegacy',
    component: () => import("@/components/FlatCashbackHeaderLegacy"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/flat-cashback/documents/:header_id',
    name: 'FlatCashbackHeader',
    component: () => import("@/components/FlatCashbackHeader"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/oddments/documents',
    name: 'OddmentsDocuments',
    component: () => import("@/components/OddmentsList"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/documents/print',
    name: 'DocumentPrint',
    component: () => import("@/components/PrintDocument.vue")
  },
  {
    path: '/oddments/documents/:header_id',
    name: 'OddmentsHeader',
    component: () => import("@/components/OddmentsHeader"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/oddments/documents-legacy/:header_id',
    name: 'OddmentsHeaderLegacy',
    component: () => import("@/components/OddmentsHeaderLegacy"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/instruction',
    name: 'Instruction',
    component: () => import("@/components/Instruction"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reading/documents/:header_id',
    name: 'ReadingDocumentHeader',
    component: () => import("@/components/CounterHeader"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/balance-detail',
    name: 'BalanceDetail',
    component: () => import("@/components/BalanceDetail"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reading/documents-legacy/:header_id',
    name: 'ReadingDocumentHeaderLegacy',
    component: () => import("@/components/CounterHeaderLegacy"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports/:report_name',
    name: 'Reports',
    component: () => import("@/components/PrintReport"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/general-settings',
    name: 'GeneralSettings',
    component: () => import("@/components/GeneralSettings"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/accounting-general-settings',
    name: 'AccountingGeneralSettings',
    component: () => import("@/components/AccountingGeneralSettings"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/building-counter',
    name: 'BuildingCounter',
    component: () => import("@/components/BuildingCounterView"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/flat-indicator',
    name: 'FlatIndicator',
    component: () => import("@/components/TariffGroupView"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/fill-service',
    name: 'FillService',
    component: () => import("@/components/for_fill/FillService"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/fill-table-and-send-sms',
    name: 'FillTableAndSendSms',
    component: () => import("@/components/for_fill/FillTableAndSendSms.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/fill-postal-index',
    name: 'FillPostalIndex',
    component: () => import("@/components/for_fill/FillPostalIndex.vue"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/fill-entrance-floor',
    name: 'FillEntranceAndFloor',
    component: () => import("@/components/for_fill/FillFloorAndEntrance"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/fill-checker',
    name: 'FillChecker',
    component: () => import("@/components/for_fill/FillChecker"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/cabinet-stats',
    name: 'CabinetStats',
    component: () => import("@/components/PersonalCabinetStats"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/fill-tariff-group',
    name: 'FillTariffGroup',
    component: () => import("@/components/for_fill/FillTariffGroup"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/admin/organizations',
    name: 'admin-organizations',
    component: () => import("@/components/admin/AdminOrganization"),
    meta: {
      requiresAuth: true,
      superUser: true
    },
  },
  {
    path: '/user/activate/:hash',
    name: 'UserActivate',
    component: () => import("@/components/UserActivate"),
    meta: {
      requiresAuth: false,
      layout: 'without'
    },
  },
  {
    path: '/users-management',
    name: 'UsersManagement',
    component: () => import("@/components/Users"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: () => import("@/components/Profile"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/voting-informing',
    name: 'VotingAndInforming',
    component: () => import("@/components/Voting"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/payment-import',
    name: 'PaymentImport',
    component: () => import("@/components/PaymentImport"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/banks',
    name: 'Banks',
    component: () => import("@/components/BankView"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/eps-import-payments',
    name: 'EpsPayments',
    component: () => import("@/components/eps_payments"),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/personal-work-place',
    name: 'PersonalWorkPlace',
    component: () => import("@/components/PersonalDashboard/PersonalWorkPlace"),
    meta: {
      requiresAuth: true,
      layout: 'without'
    },
  },
  {
    path: '/represent-personal-cabinet',
    name: 'RepresentPersonalWorkplace',
    component: () => import("@/components/PersonalDashboard/PersonalWorkPlace"),
    meta: {
      requiresAuth: true,
      layout: 'without'
    },
  },
  {
    path: '/send-media',
    name: 'SendMedia',
    component: () => import("@/components/MediaSend"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/accounting-regulatory-reports',
    name: 'RegulatoryReports',
    component: () => import("@/components/RegulatoryReports.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user-stats',
    name: 'UserStats',
    component: () => import("@/components/UserStats"),
    meta: {
      requiresAuth: true,
      // superUser: true
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const redirect_after_login = (to) => {
  if (!to) return false

  const cab = router.app.$store.getters.getPersonalCabinetHome
  const accounting = router.app.$store.getters.isAccounting
  const legacy = router.app.$store.getters.isLegacy
  const dispatcher = router.app.$store.getters.isDispatcher

  if (to.name === 'afterLogin') {
    if (cab) {
      return {name: 'PersonalWorkPlace'}
    } else {
      if (legacy || accounting) {
        if (accounting) {
          return { name: 'AccountingDesktopView', params: { page_name: 'bank' } }
        }
        if (legacy) {
          return { name: 'LegacyDesktop' }
        }
      } else {
        if (dispatcher) {
          return { name: 'DispatcherCall' }
        } else {
          if (to.query.redirect) {
            return to.query.redirect || ''
          } else {
            return {name: 'Desktop'}
          }
        }


      }
    }
  }

}

router.beforeEach((to, from, next) => {
  const isSuperUser = router.app.$store.getters.isSuperUser

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      if (to.matched.some(record => record.meta.superUser)) {
        if (!isSuperUser) {
          next(false)
        } else {
          if (to.name === 'afterLogin') {
            next(redirect_after_login(to))
          } else {
            next()
          }
        }
      } else {
        if (to.name === 'afterLogin') {
          next(redirect_after_login(to))
        } else {
          next()
        }
      }
    }
  } else {
    if (to.name === 'afterLogin') {
      next(redirect_after_login(to))
    } else {
      next()
    }
  }
})


export default router
