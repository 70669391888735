const cityTypes_select = [
    {value: 'city', text: 'місто'},
    {value: 'urban_village', text: 'смт'},
    {value: 'village', text: 'село'},
    {value: 'hamlet', text: 'хутір'},
]

const streetTypes_select = [
    {value: 'street', text: 'вулиця'},
    {value: 'lane', text: 'провулок'},
    {value: 'prospectus', text: 'проспект'},
    {value: 'alley', text: 'алея'},
    {value: 'square', text: 'площа'},
    {value: 'area', text: 'майдан'},
    {value: 'thoroughfare', text: 'проїзд'},
    {value: 'dead_end', text: 'тупик'},
    {value: 'boulevard', text: 'бульвар'},
]

const balance_types = [
    {text: 'Активний', value: 'active', short: 'А', color: 'success--text'},
    {text: 'Пасивний', value: 'passive', short: 'П', color: 'secondary--text'},
    {text: 'Активно-пасивний', value: 'both', short: 'АП', color: 'primary--text'},
]

const getBalanceType = function (payload, short = false, color = false) {
    const find = balance_types.find(item => item.value === payload)
    if (find) {
        if (color) {
            return find.color || 'success'
        } else {
            return short ? find.short : find.text
        }
    }
    return ''
}

const onBeforeUnload = function (event) {
    if (typeof event == 'undefined') {
        event = window.event;
    }
    event.Bubbles = true
    event.preventDefault()
    event.returnValue = null;     // Gecko + IE
    return event.returnValue;
}

// const report_components = {
//     general_report: {
//         name: 'general_report',
//         orientation: 'page-a4-landscape-auto',
//         page_wrapper: true,
//         month_select: false,
//         show_expand: false,
//         show_dates: true,
//         show_scale: true,
//         page_splitter: false,
//         xlsx_export: true
//     },
//     general_report_by_flat: {
//
//     }
// }

const check_show_page = function (name, config) {
    if (name === 'SiteDebt') {
        if (!config.show_debt) {
            return false
        }
    }

    if (name === 'SiteManagement') {
        if (!config.show_management) {
            return false
        }
    }

    if (name === 'SiteContacts') {
        if (!config.show_contacts) {
            return false
        }
    }

    if (name === 'SiteContacts') {
        if (!config.show_contacts) {
            return false
        }
    }

    if (name === 'SiteDocuments') {
        if (!config.show_documents) {
            return false
        }
    }

    if (name === 'SiteGallery') {
        if (!config.show_gallery) {
            return false
        }
    }
    return true
}

const subconto_types = [
    {text: 'Номенклатура', value: 'nomenclature'},
    {text: 'Контрагенти', value: 'contractor'},
    {text: 'Фізичні особи', value: 'natural_person'},
    {text: 'Склади', value: 'warehouse'},
    {text: 'Підрозділи', value: 'subdivision'},
    {text: 'Працівники', value: 'person'},
    {text: 'Валюти', value: 'currency'},
    {text: 'Одиниці виміру', value: 'unit_type'},
    {text: 'Банкіський рахунок', value: 'bank_account'},
    {text: 'Стаття руху грошових коштів', value: 'cash_flow_item'},
    {text: 'Договора контрагентів', value: 'contract'},
    {text: 'Основні засоби', value: 'fixed_assets'},
    {text: 'Статті витрат', value: 'cost_items'},
    {text: 'Нематеріальні активи', value: 'intangible_assets'},
    {text: 'Біологічні активи', value: 'biological_assets'},
    {text: 'Статі доходів', value: 'income_items'},
    {text: 'Номенклатурні групи', value: 'nomenclature_groups'},
    {text: 'Податки', value: 'taxes'},
]

const getSubcontoType = function (payload) {
    const value = subconto_types.find(item => item.value === payload)
    if (value) {
        return value.text
    }
    return ''
}

const buildingTypes_select = [
    {value: 'high_rise', text: 'багатоквартирний'},
    {value: 'private', text: 'приватний'},
    {value: 'manor', text: 'садиба'},
    {value: 'cottage', text: 'дача'},
    {value: 'industrial', text: 'промисловий'},
]

const buildingTypes = {
    'high_rise': 'багатоквартирний',
    'private': 'приватний',
    'manor': 'садиба',
    'cottage': 'дача',
    'industrial': 'промисловий'
}


const registrationType = {
    'registered': 'зареєстрований',
    'lives': 'проживає',
    'discharged': 'виписаний',
    'empty': 'без відомостей',
}

const registrationTypeSelect = [
    {value: 'registered', text: 'зареєстрований'},
    {value: 'lives', text: 'проживає'},
    {value: 'discharged', text: 'виписаний'},
    {value: 'empty', text: 'без відомостей'},
]

const monetaryServiceSelect = [
    {value: 1, text: 'Теплова енергія'},
    {value: 2, text: 'Гаряча вода'},
    {value: 3, text: 'Холодна вода'},
    {value: 4, text: 'Газ'},
    {value: 5, text: 'Електроенергія (код 108.2)'},
    {value: 6, text: 'Вивіз сміття'},
    {value: 13, text: 'Вивіз нечистот'},
    {value: 14, text: 'Ремонтні відходи'},
    {value: 7, text: 'Водовідведення'},
    {
        value: 8,
        text: 'Внески за вузол обліку теплової енергії'
    },
    {
        value: 9,
        text: 'Внески за вузол обліку гарячої води'
    },
    {
        value: 10,
        text: 'Внески за вузол обліку питної води'
    },
    {
        value: 11,
        text: 'Внески за ОСББ (код 101)'
    },
    {
        value: 12,
        text: 'Утримання будинку (код 101)'
    },
    {
        value: 15,
        text: 'Абонент.обслуг. хол. вода'
    },
    {
        value: 16,
        text: 'Абонент.обслуг. гар. вода'
    },
    {
        value: 17,
        text: 'Абонент.обслуг. водовідведення'
    },
    {
        value: 18,
        text: 'Абонент.обслуг. ТПВ'
    },
    {
        value: 19,
        text: 'Поводження з великогабаритних відходів'
    },
    {
        value: 20,
        text: 'Поводження з ремонтними відходами'
    },
    {
        value: 21,
        text: 'Електроенергія (код 108.3)'
    }
]

const flat_ownership_types_select = [
    {value: 'private', text: 'приватна'},              //приватна
    {value: 'state', text: 'державна'},                  //державна
    {value: 'departmental', text: 'відомча'},    //відомча
    {value: 'cooperative', text: 'кооперативна'},      //кооперативна
    {value: 'office', text: 'службова'},                //службова
    {value: 'maneuverable', text: 'маневрена'},    //маневрена
    {value: 'off_balance', text: 'небалансова'}       //небалансова
]

const document_ownership_types_select = [
    {value: 'certificate_of_ownership', text: 'свідоцтво про право власності'},              //приватна
    {value: 'certificate_of_inheritance', text: 'свідоцтво про право на спадщину'},                  //державна
    {value: 'certificate_of_acquisition_of_property', text: 'свідоцтво про придбання майна з прилюдних торгів'},    //відомча
    {value: 'decision_on_securing_real_estate', text: 'рішення про закріплення нерухомого майна '},      //кооперативна
    {value: 'will', text: 'заповіт'},                //службова
    {value: 'other_document', text: 'інший документ'},    //маневрена
    {value: 'technical_passport', text: 'технічний паспорт'}       //небалансова
]


const streetTypes = {
    'street': 'вулиця',
    'lane': 'провулок',
    'prospectus': 'проспект',
    'alley': 'алея',
    'square': 'площа',
    'area': 'майдан',
    'thoroughfare': 'проїзд',
    'dead_end': 'тупик',
    'boulevard': 'булбвар'
}

const streetTypesShort = {
    'street': 'вул.',
    'lane': 'пров.',
    'prospectus': 'пр-кт.',
    'alley': 'алея',
    'square': 'пл.',
    'area': 'май.',
    'thoroughfare': 'проїзд ',
    'dead_end': 'тупик ',
    'boulevard': 'б-ар'
}

const cityTypes = {
    'city': 'місто',
    'urban_village': 'смт',
    'village': 'село',
    'hamlet': 'хутір'
}

const cityTypesShort = {
    'city': 'м.',
    'urban_village': 'смт.',
    'village': 'с.',
    'hamlet': 'х.'
}

const bankAccountTypes = {
    'current_account': 'Розрахунковий',
    'deposit_account': 'Депозитний',
    'hospital_account': 'Лікарняний',
}

const bankAccountTypesSelect = [
    {value: 'current_account', text: 'Розрахунковий'},
    {value: 'deposit_account', text: 'Депозитний'},
    {value: 'hospital_account', text: 'Лікарняний'},
]

const categoriesSelect = [
    {value: 'director', text: 'Директор'},
    {value: 'chief_accountant', text: 'Головний бухгалтер'},
    {value: 'accountant', text: 'Бухгалетр'},
    {value: 'warehouse_manager', text: 'Зав. складом'}
]

const absentReasonSelect = [
    {value: 'abroad', text: 'Виїзд за кордон'},
    {value: 'military_service', text: 'Військова служба'},
    {value: 'hospital', text: 'Лікарняний'},
    {value: 'prison', text: 'Позбавлення волі'},
    {value: 'other', text: 'Інша причина'},
    {value: 'unknown', text: 'Причина невідома'},
]

const addCounterValueMethod = [
    {value: 'MANUAL', text: 'Подано вручну'},
    {value: 'BY_PHONE', text: 'Подано по телефону'},
    {value: 'BY_EMAIL', text: 'Подано по email'},
    {value: 'BY_CONTROLLER', text: 'Подано контролером'},
    {value: 'ARCHIVE', text: 'Архівні показник'},
    {value: 'BY_OLD_PROGRAM', text: 'Згідно попередньої програми'},
    {value: 'BY_STATEMENT', text: 'Подано по відомості'},
    {value: 'BY_FULL_MONTH', text: 'Дорах. до повного місяця'},
    {value: 'BY_USER', text: 'Особистий кабінет'},
    {value: 'BY_AVG', text: 'По середньому'},
    {value: 'DOWNLOAD', text: 'Завантажено'},
    {value: 'UNKNOWN', text: 'Невідомо'},
]

const addBuildingCounterValueMethod = [
    {value: 'MANUAL', text: 'Подано вручну'},
    {value: 'BY_CONTROLLER', text: 'Подано контролером'},
    {value: 'ARCHIVE', text: 'Архівні показник'},
    {value: 'BY_OLD_PROGRAM', text: 'Згідно попередньої програми'},
]

const getAddCounterValueMethod = (text) => {
    return (addCounterValueMethod.find(item => item.value === text) || {}).text || ''
}

const getAddBuildingCounterValueMethod = (text) => {
    return (addBuildingCounterValueMethod.find(item => item.value === text) || {}).text || ''
}

const getFlatType = (text) => {
    return (flat_ownership_types_select.find(item => item.value === text) || {}).text || ''
}

const social_calc_type_select = [
    {text: 'Утримання або управління будинком', value: 'house_pay_type'},
    {text: 'Водопостачання/Водовідведення', value: 'water_type'},
    {text: 'Вивіз сміття', value: 'trash_type'},
    {text: 'Елетропостачання', value: 'electric_type'},
    {text: 'Теплопостачання', value: 'warm_type'},
    {text: 'Газопостачання', value: 'gaz_type'},
]

const accountIcons =
    {
        'house_pay': {icon: 'mdi-home-city-outline', color: 'success'},
        'house_pay_3': {icon: 'mdi-home-city-outline', color: 'grey darken-2'},
        'house_pay_4': {icon: 'mdi-city-variant-outline', color: 'teal darken-2'},
        'house_pay_5': {icon: 'mdi-city-variant-outline', color: 'success darken-1'},
        'house_pay_1': {icon: 'mdi-home-variant-outline', color: 'primary darken-1'},
        'house_pay_2': {icon: 'mdi-domain', color: 'purple'},
        'car_1': {icon: 'mdi-car-side', color: 'teal'},
        'webcam': {icon: 'mdi-webcam', color: 'error lighten-1'},
        'shield': {icon: 'mdi-shield-home', color: 'pink darken-1'},
        'trash': {icon: 'mdi-trash-can-outline', color: 'success'},
        'trash_1': {icon: 'mdi-delete-variant', color: 'secondary'},
        'trash_2': {icon: 'mdi-delete-outline', color: 'teal darken-1'},
        'speed_1': {icon: 'mdi-speedometer-medium', color: 'grey darken-1'},
        'liquid_sewage': {icon: 'mdi-tanker-truck', color: 'primary lighten-1'},
        'sewage_1': {icon: 'mdi-tanker-truck', color: 'orange darken-1'},
        'wrench-1': {icon: 'mdi-wrench-outline', color: 'teal darken-1'},
        'support-1': {icon: 'mdi-face-agent', color: 'blue lighten-1'},
        'gavel-1': {icon: 'mdi-gavel', color: 'cyan darken-2'},
        'sync-1': {icon: 'mdi-archive-sync-outline', color: 'secondary'},
        'contribution': {icon: 'mdi-cash-check', color: 'purple'},
        'garage': {icon: 'mdi-garage-variant', color: 'brown'},
        'sewerage': {icon: 'mdi-pipe', color: 'grey darken-1'},
        'electro': {icon: 'mdi-lightbulb-multiple-outline', color: 'orange'},
        'electro_1': {icon: 'mdi-lightbulb-on-outline', color: 'warning darken-1'},
        'water_cold': {icon: 'mdi-water-check-outline', color: 'blue'},
        'water_cold_1': {icon: 'mdi-water-thermometer-outline', color: 'blue'},
        'water_hot': {icon: 'mdi-water-check-outline', color: 'red'},
        'water_hot_1': {icon: 'mdi-water-thermometer-outline', color: 'red'},
        'heating': {icon: 'mdi-radiator', color: 'red lighten-2'},
        'gas': {icon: 'mdi-lightning-bolt-outline', color: 'orange lighten-1'},
        'one-time': {icon: 'mdi-animation-outline', color: 'primary'},
        'cow': {icon: 'mdi-cow', color: 'brown'},
        'horse': {icon: 'mdi-horse', color: 'brown darken-1'},
        'dog': {icon: 'mdi-dog-side', color: 'brown darken-2'},
        'watering-can': {icon: 'mdi-watering-can', color: 'blue lighten-1'},
        'home-battery': {icon: 'mdi-home-battery', color: 'secondary darken-1'},
        'camera-cctv': {icon: 'mdi-cctv', color: 'purple lighten-1'},
        'finance-hand': {icon: 'mdi-finance', color: 'yellow darken-2'},
        'hand-coin': {icon: 'mdi-hand-coin-outline', color: 'green darken-1'},
    }


const generateIconSelect = () => {
    return Object.values(accountIcons).map(item => {
        return Object.assign({text: '', value: item})
    })
}

const accountIconSelect = [
    {text: '', value: {icon: 'mdi-home-city-outline', color: 'success'}},
    {text: '', value: {icon: 'mdi-home-city-outline', color: 'grey darken-2'}},
    {text: '', value: {icon: 'mdi-city-variant-outline', color: 'teal darken-2'}},
    {text: '', value: {icon: 'mdi-city-variant-outline', color: 'success darken-1'}},
    {text: '', value: {icon: 'mdi-home-variant-outline', color: 'primary darken-1'}},
    {text: '', value: {icon: 'mdi-domain', color: 'purple'}},
    {text: '', value: {icon: 'mdi-car-side', color: 'teal'}},
    {text: '', value: {icon: 'mdi-webcam', color: 'error lighten-1'}},
    {text: '', value: {icon: 'mdi-shield-home', color: 'pink darken-1'}},
    {text: '', value: {icon: 'mdi-trash-can-outline', color: 'success'}},
    {text: '', value: {icon: 'mdi-delete-variant', color: 'secondary'}},
    {text: '', value: {icon: 'mdi-delete-outline', color: 'teal darken-1'}},
    {text: '', value: {icon: 'mdi-speedometer-medium', color: 'grey darken-1'}},
    {text: '', value: {icon: 'mdi-tanker-truck', color: 'primary lighten-1'}},
    {text: '', value: {icon: 'mdi-tanker-truck', color: 'orange lighten-1'}},
    {text: '', value: {icon: 'mdi-cash-check', color: 'purple'},},
    {text: '', value: {icon: 'mdi-garage-variant', color: 'brown'}},
    {text: '', value: {icon: 'mdi-pipe', color: 'grey darken-1'}},
    {text: '', value: {icon: 'mdi-lightbulb-multiple-outline', color: 'orange'}},
    {text: '', value: {icon: 'mdi-lightbulb-on-outline', color: 'warning darken-1'}},
    {text: '', value: {icon: 'mdi-water-check-outline', color: 'blue'}},
    {text: '', value: {icon: 'mdi-water-thermometer-outline', color: 'blue'}},
    {text: '', value: {icon: 'mdi-water-check-outline', color: 'red'}},
    {text: '', value: {icon: 'mdi-water-thermometer-outline', color: 'red'}},
    {text: '', value: {icon: 'mdi-radiator', color: 'red lighten-2'}},
    {text: '', value: {icon: 'mdi-lightning-bolt-outline', color: 'orange lighten-1'}},
    {text: '', value: {icon: 'mdi-animation-outline', color: 'primary'}},
    {text: '', value: {icon: 'mdi-cow', color: 'brown'}},
    {text: '', value: {icon: 'mdi-horse', color: 'brown darken-1'}},
    {text: '', value: {icon: 'mdi-dog-side', color: 'brown darken-2'}},
    {text: '', value: {icon: 'mdi-watering-can', color: 'blue lighten-1'}},
    {text: '', value: {icon: 'mdi-home-battery', color: 'secondary darken-1'}},
    {text: '', value: {icon: 'mdi-cctv', color: 'purple lighten-1'}},
    {text: '', value: {icon: 'mdi-finance', color: 'yellow darken-1'}},
    {text: '', value: {icon: 'mdi-hand-coin-outline', color: 'green darken-1'}}
]


const cityIcons = {
    'city': {icon: 'mdi-city-variant-outline', color: 'primary lighten-1'},
    'urban_village': {icon: 'mdi-home-city-outline', color: 'secondary'},
    'village': {icon: 'mdi-home-silo-outline', color: 'teal'},
    'hamlet': {icon: 'mdi-home-circle-outline', color: 'purple'},
}

const streetIcons = {
    'street': {icon: 'mdi-map-marker', color: 'primary'}, //вулиця
    'lane': {icon: 'mdi-map-marker-plus-outline', color: 'success lighten-1'}, //провулок
    'prospectus': {icon: 'mdi-map-marker-left-outline', color: 'secondary'}, //проспект
    'alley': {icon: 'mdi-map-marker-radius-outline', color: 'success'}, //алея
    'square': {icon: 'mdi-map-outline', color: 'purple'}, //площа
    'area': {icon: 'mdi-map-marker-down', color: 'tile'}, //майдан
    'thoroughfare': {icon: 'mdi-map-marker-distance', color: 'purple lighten-2'}, //проїзд
    'dead_end': {icon: 'mdi-map-marker-remove-variant', color: 'pink'}, //тупик
}

const buildingIcons = {
    'high_rise': {icon: 'mdi-city-variant-outline', color: 'primary'}, //вулиця
    'private': {icon: 'mdi-home-city-outline', color: 'success'}, //провулок
    'manor': {icon: 'mdi-home', color: 'secondary'}, //проспект
    'cottage': {icon: 'mdi-home-variant-outline', color: 'purple'}, //алея
    'industrial': {icon: 'mdi-domain', color: 'pink'}, //площа
}

const contactIcons = {
    'phone': {icon: 'mdi-cellphone', color: 'teal'}, //вулиця
    'email': {icon: 'mdi-email-outline', color: 'secondary'}, //провулок
}

const calculateMethods = {
    'by_square': 'по площі',
    'by_fixed': 'фіксована сума',
    'by_normative': 'по нормативах',
    'by_counter': 'по лічильнику'
}

const divideMethods = {
    'divide_volume_tariff': 'Роз. об’єму * тариф',
    'divide_sum': 'Роз. суми',
    'multiply_normative_tariff': 'Норм.показ *тариф',
    'fixed_value': 'Фіксоване',
    'multiply_volume_by_tariff': 'Вказаний об’єм * тариф'
}

const calculateMethodsSelect = [
    {value: 'by_square', text: 'по площі'},
    {value: 'by_fixed', text: 'фіксована сума'},
    {value: 'by_normative', text: 'по нормативах'},
    {value: 'by_counter', text: 'по лічильнику'}
]

const buildingCalcMthod = [
    {value: 'WATER_METHOD', text: 'Методика для постачання води'},
    {value: 'WATER_BY_ALL_METHOD', text: 'Методика для пост.води (на усіх)'},
    {value: 'WATER_BY_SQUARE', text: 'Методика для пост.води (по площі)'},
    {value: 'WATER_PROPORTIONAL_COUNTER_VALUES', text: 'Методика для пост.води (пропорц. показ.)'},
    {value: 'ELECTRICITY_METHOD', text: 'Методика для електроенергії'},
    {value: 'ELECTRICITY_BY_ALL_METHOD', text: 'Методика для електро. (на усіх)'},
    {value: 'ELECTRICITY_BY_SQUARE', text: 'Методика для електро. (по площі)'},
    {value: 'ELECTRICITY_PROPORTIONAL_COUNTER_VALUES', text: 'Методика для електро. (пропорц. показ.)'},
    {value: 'GAZ_METHOD', text: 'Методика для газу'},
    {value: 'GAZ_BY_ALL_METHOD', text: 'Методика для газу (на усіх)'},
    {value: 'GAZ_BY_SQUARE', text: 'Методика для газу (по площі)'},
    {value: 'GAZ_PROPORTIONAL_COUNTER_VALUES', text: 'Методика для газу (пропорц. показ.)'},
    {value: 'HEATING_OLD_METHOD', text: 'Методика для теплопостачання (застаріла)'},
    {value: 'HEATING_NEW_METHOD', text: 'Методика для теплопостачання (нова)'},
]

const statusTypeSelect = [
    {value: 'usual', text: 'Звичайне', color: 'primary--text', border: 'var(--v-primary-base)', class: 'primary'},
    {
        value: 'important',
        text: 'Важливе',
        color: 'secondary--text text--darken-1',
        border: 'var(--v-secondary-darken1)',
        class: 'secondary'
    },
    {value: 'very_important', text: 'Дуже важливе', color: 'error--text', border: 'var(--v-error-base)'},
    {
        value: 'urgent',
        text: 'Надзвичайно важливе',
        color: 'error--text text--darken-1',
        border: 'var(--v-error-darken1)',
        class: 'error'
    },
]

const getStatusType = (value, col = 'text') => {
    return (statusTypeSelect.find(item => item.value === value) || {})[col] || ''
}

const mediaSendTypeSelect = [
    {value: 'SMS', text: 'СМС'},
    {value: 'EMAIL', text: 'ЕЛ.ПОШТА'},
]

const getMediaSendType = (value) => {
    return (mediaSendTypeSelect.find(i => i.value === value) || {}).text
}

const mediaSendStatusSelect = [
    {
        value: 'success',
        text: 'Успішно',
        color: 'success',
        icon: 'mdi-text-box-check-outline',
        style: 'border-color: var(--v-success-base) !important;'
    },
    {
        value: 'wait',
        text: 'Очікування',
        color: 'secondary',
        icon: 'mdi-progress-clock',
        style: 'border-color: var(--v-secondary-base) !important;'
    },
    {
        value: 'cancel',
        text: 'Відмінено',
        color: 'error',
        icon: 'mdi-text-box-remove-outline',
        style: 'border-color: var(--v-error-base) !important;'
    },
    {
        value: 'progress',
        text: 'У процесі',
        color: 'primary',
        icon: 'mdi-sync',
        style: 'border-color: var(--v-primary-base) !important;'
    },
    {
        value: 'wrong_phone',
        text: 'Невірний номер',
        color: 'error',
        icon: 'mdi-cellphone-remove',
        style: 'border-color: var(--v-error-base) !important;'
    },
    {
        value: 'cancel',
        text: 'Невірний номер/роумінг',
        color: 'error',
        icon: 'mdi-cellphone-remove',
        style: 'border-color: var(--v-error-base) !important;'
    },
    {
        value: 'rejected',
        text: 'Відхилено',
        color: 'error',
        icon: 'mdi-cellphone-remove',
        style: 'border-color: var(--v-error-base) !important;'
    },
]

const mediaReplaceParams = [
    {param: 'start_balance', text: 'Сальдо на поч.місяця', value: '11111.10'},
    {param: 'start_balance_exclude_pay', text: 'Сальдо на поч.місяця (за мінусом оплати)', value: '11111.10'},
    {param: 'end_balance', text: 'Сальдо на кін.місяця', value: '11111.10'},
    {param: 'flat_owner_full_name', text: 'Власник (П.І.Б)', value: 'Тестовий__ Власник____ Квартири__'},
    {param: 'flat_owner_short_name', text: 'Власник (Ініціали)', value: 'Тестовий__ В. К. '},
    {param: 'address', text: 'Адреса абонента (скор.)', value: 'вул. Волошкова-Бічна 10 кв. 1'},
    {
        param: 'full_address',
        text: 'Адреса абонента (повна)',
        value: 'м. Вигадане-На-Березі, вул. Волошкова-Бічна 10 кв. 1'
    },
    {param: 'person_id', text: 'Особовий рахунок', value: '10005565'},
    {param: 'person_hash', text: 'Код для персонального кабінету', value: '778899665544'},
]

const getMediaSendStatus = (value, field = 'text') => {
    return (mediaSendStatusSelect.find(i => i.value === value) || {})[field] || ''
}

const districtSelect = [
    {
        text: 'Вінницький',
        value: 'Вінницький',
        region: 'Вінницька'
    },
    {
        text: 'Гайсинський',
        value: 'Гайсинський',
        region: 'Вінницька'
    },
    {
        text: 'Жмеринський',
        value: 'Жмеринський',
        region: 'Вінницька'
    },
    {
        text: 'Могилів-Подільський',
        value: 'Могилів-Подільський',
        region: 'Вінницька'
    },
    {
        text: 'Тульчинський',
        value: 'Тульчинський',
        region: 'Вінницька'
    },
    {
        text: 'Хмільницький',
        value: 'Хмільницький',
        region: 'Вінницька'
    },
    {
        text: 'Володимир-Волинський',
        value: 'Володимир-Волинський',
        region: 'Волинська'
    },
    {
        text: 'Камінь-Каширський',
        value: 'Камінь-Каширський',
        region: 'Волинська'
    },
    {
        text: 'Ковельський',
        value: 'Ковельський',
        region: 'Волинська'
    },
    {
        text: 'Луцький',
        value: 'Луцький',
        region: 'Волинська'
    },
    {
        text: 'Дніпровський',
        value: 'Дніпровський',
        region: 'Дніпропетровська'
    },
    {
        text: 'Кам’янський',
        value: 'Кам’янський',
        region: 'Дніпропетровська'
    },
    {
        text: 'Криворізький',
        value: 'Криворізький',
        region: 'Дніпропетровська'
    },
    {
        text: 'Нікопольський',
        value: 'Нікопольський',
        region: 'Дніпропетровська'
    },
    {
        text: 'Новомосковський',
        value: 'Новомосковський',
        region: 'Дніпропетровська'
    },
    {
        text: 'Павлоградський',
        value: 'Павлоградський',
        region: 'Дніпропетровська'
    },
    {
        text: 'Синельниківський',
        value: 'Синельниківський',
        region: 'Дніпропетровська'
    },
    {
        text: 'Бахмутський',
        value: 'Бахмутський',
        region: 'Донецька'
    },
    {
        text: 'Волноваський',
        value: 'Волноваський',
        region: 'Донецька'
    },
    {
        text: 'Горлівський',
        value: 'Горлівський',
        region: 'Донецька'
    },
    {
        text: 'Донецький',
        value: 'Донецький',
        region: 'Донецька'
    },
    {
        text: 'Кальміуський',
        value: 'Кальміуський',
        region: 'Донецька'
    },
    {
        text: 'Краматорський',
        value: 'Краматорський',
        region: 'Донецька'
    },
    {
        text: 'Маріупольський',
        value: 'Маріупольський',
        region: 'Донецька'
    },
    {
        text: 'Покровський',
        value: 'Покровський',
        region: 'Донецька'
    },
    {
        text: 'Бердичівський',
        value: 'Бердичівський',
        region: 'Житомирська'
    },
    {
        text: 'Житомирський',
        value: 'Житомирський',
        region: 'Житомирська'
    },
    {
        text: 'Коростенський',
        value: 'Коростенський',
        region: 'Житомирська'
    },
    {
        text: 'Новоград-Волинський',
        value: 'Новоград-Волинський',
        region: 'Житомирська'
    },
    {
        text: 'Берегівський',
        value: 'Берегівський',
        region: 'Закарпатська'
    },
    {
        text: 'Мукачівський',
        value: 'Мукачівський',
        region: 'Закарпатська'
    },
    {
        text: 'Рахівський',
        value: 'Рахівський',
        region: 'Закарпатська'
    },
    {
        text: 'Тячівський',
        value: 'Тячівський',
        region: 'Закарпатська'
    },
    {
        text: 'Ужгородський',
        value: 'Ужгородський',
        region: 'Закарпатська'
    },
    {
        text: 'Хустський',
        value: 'Хустський',
        region: 'Закарпатська'
    },
    {
        text: 'Бердянський',
        value: 'Бердянський',
        region: 'Запорізька'
    },
    {
        text: 'Василівський',
        value: 'Василівський',
        region: 'Запорізька'
    },
    {
        text: 'Запорізький',
        value: 'Запорізький',
        region: 'Запорізька'
    },
    {
        text: 'Мелітопольський',
        value: 'Мелітопольський',
        region: 'Запорізька'
    },
    {
        text: 'Пологівський',
        value: 'Пологівський',
        region: 'Запорізька'
    },
    {
        text: 'Верховинський',
        value: 'Верховинський',
        region: 'Івано-Франківська'
    },
    {
        text: 'Івано-Франківський',
        value: 'Івано-Франківський',
        region: 'Івано-Франківська'
    },
    {
        text: 'Калуський',
        value: 'Калуський',
        region: 'Івано-Франківська'
    },
    {
        text: 'Коломийський',
        value: 'Коломийський',
        region: 'Івано-Франківська'
    },
    {
        text: 'Косівський',
        value: 'Косівський',
        region: 'Івано-Франківська'
    },
    {
        text: 'Надвірнянський',
        value: 'Надвірнянський',
        region: 'Івано-Франківська'
    },
    {
        text: 'Білоцерківський',
        value: 'Білоцерківський',
        region: 'Київська'
    },
    {
        text: 'Бориспільський',
        value: 'Бориспільський',
        region: 'Київська'
    },
    {
        text: 'Броварський',
        value: 'Броварський',
        region: 'Київська'
    },
    {
        text: 'Бучанський',
        value: 'Бучанський',
        region: 'Київська'
    },
    {
        text: 'Вишгородський',
        value: 'Вишгородський',
        region: 'Київська'
    },
    {
        text: 'Обухівський',
        value: 'Обухівський',
        region: 'Київська'
    },
    {
        text: 'Фастівський',
        value: 'Фастівський',
        region: 'Київська'
    },
    {
        text: 'Голованівський',
        value: 'Голованівський',
        region: 'Кіровоградська'
    },
    {
        text: 'Кропивницький',
        value: 'Кропивницький',
        region: 'Кіровоградська'
    },
    {
        text: 'Новоукраїнський',
        value: 'Новоукраїнський',
        region: 'Кіровоградська'
    },
    {
        text: 'Олександрійський',
        value: 'Олександрійський',
        region: 'Кіровоградська'
    },
    {
        text: 'Алчевський',
        value: 'Алчевський',
        region: 'Луганська'
    },
    {
        text: 'Довжанський',
        value: 'Довжанський',
        region: 'Луганська'
    },
    {
        text: 'Луганський',
        value: 'Луганський',
        region: 'Луганська'
    },
    {
        text: 'Ровеньківський',
        value: 'Ровеньківський',
        region: 'Луганська'
    },
    {
        text: 'Сватівський',
        value: 'Сватівський',
        region: 'Луганська'
    },
    {
        text: 'Сєвєродонецький',
        value: 'Сєвєродонецький',
        region: 'Луганська'
    },
    {
        text: 'Старобільський',
        value: 'Старобільський',
        region: 'Луганська'
    },
    {
        text: 'Щастинський',
        value: 'Щастинський',
        region: 'Луганська'
    },
    {
        text: 'Дрогобицький',
        value: 'Дрогобицький',
        region: 'Львівська'
    },
    {
        text: 'Золочівський',
        value: 'Золочівський',
        region: 'Львівська'
    },
    {
        text: 'Львівський',
        value: 'Львівський',
        region: 'Львівська'
    },
    {
        text: 'Самбірський',
        value: 'Самбірський',
        region: 'Львівська'
    },
    {
        text: 'Стрийський',
        value: 'Стрийський',
        region: 'Львівська'
    },
    {
        text: 'Червоноградський',
        value: 'Червоноградський',
        region: 'Львівська'
    },
    {
        text: 'Яворівський',
        value: 'Яворівський',
        region: 'Львівська'
    },
    {
        text: 'Баштанський',
        value: 'Баштанський',
        region: 'Миколаївська'
    },
    {
        text: 'Вознесенський',
        value: 'Вознесенський',
        region: 'Миколаївська'
    },
    {
        text: 'Миколаївський',
        value: 'Миколаївський',
        region: 'Миколаївська'
    },
    {
        text: 'Первомайський',
        value: 'Первомайський',
        region: 'Миколаївська'
    },
    {
        text: 'Березівський',
        value: 'Березівський',
        region: 'Одеська'
    },
    {
        text: 'Білгород-Дністровський',
        value: 'Білгород-Дністровський',
        region: 'Одеська'
    },
    {
        text: 'Болградський',
        value: 'Болградський',
        region: 'Одеська'
    },
    {
        text: 'Ізмаїльський',
        value: 'Ізмаїльський',
        region: 'Одеська'
    },
    {
        text: 'Одеський',
        value: 'Одеський',
        region: 'Одеська'
    },
    {
        text: 'Подільський',
        value: 'Подільський',
        region: 'Одеська'
    },
    {
        text: 'Роздільнянський',
        value: 'Роздільнянський',
        region: 'Одеська'
    },
    {
        text: 'Кременчуцький',
        value: 'Кременчуцький',
        region: 'Полтавська'
    },
    {
        text: 'Лубенський',
        value: 'Лубенський',
        region: 'Полтавська'
    },
    {
        text: 'Миргородський',
        value: 'Миргородський',
        region: 'Полтавська'
    },
    {
        text: 'Полтавський',
        value: 'Полтавський',
        region: 'Полтавська'
    },
    {
        text: 'Вараський',
        value: 'Вараський',
        region: 'Рівненська'
    },
    {
        text: 'Дубенський',
        value: 'Дубенський',
        region: 'Рівненська'
    },
    {
        text: 'Рівненський',
        value: 'Рівненський',
        region: 'Рівненська'
    },
    {
        text: 'Сарненський',
        value: 'Сарненський',
        region: 'Рівненська'
    },
    {
        text: 'Конотопський',
        value: 'Конотопський',
        region: 'Сумська'
    },
    {
        text: 'Охтирський',
        value: 'Охтирський',
        region: 'Сумська'
    },
    {
        text: 'Роменський',
        value: 'Роменський',
        region: 'Сумська'
    },
    {
        text: 'Сумський',
        value: 'Сумський',
        region: 'Сумська'
    },
    {
        text: 'Шосткинський',
        value: 'Шосткинський',
        region: 'Сумська'
    },
    {
        text: 'Кременецький',
        value: 'Кременецький',
        region: 'Тернопільська'
    },
    {
        text: 'Тернопільський',
        value: 'Тернопільський',
        region: 'Тернопільська'
    },
    {
        text: 'Чортківський',
        value: 'Чортківський',
        region: 'Тернопільська'
    },
    {
        text: 'Богодухівський',
        value: 'Богодухівський',
        region: 'Харківська'
    },
    {
        text: 'Ізюмський',
        value: 'Ізюмський',
        region: 'Харківська'
    },
    {
        text: 'Красноградський',
        value: 'Красноградський',
        region: 'Харківська'
    },
    {
        text: 'Куп’янський',
        value: 'Куп’янський',
        region: 'Харківська'
    },
    {
        text: 'Лозівський',
        value: 'Лозівський',
        region: 'Харківська'
    },
    {
        text: 'Харківський',
        value: 'Харківський',
        region: 'Харківська'
    },
    {
        text: 'Чугуївський',
        value: 'Чугуївський',
        region: 'Харківська'
    },
    {
        text: 'Бериславський',
        value: 'Бериславський',
        region: 'Херсонська'
    },
    {
        text: 'Генічеський',
        value: 'Генічеський',
        region: 'Херсонська'
    },
    {
        text: 'Каховський',
        value: 'Каховський',
        region: 'Херсонська'
    },
    {
        text: 'Скадовський',
        value: 'Скадовський',
        region: 'Херсонська'
    },
    {
        text: 'Херсонський',
        value: 'Херсонський',
        region: 'Херсонська'
    },
    {
        text: 'Кам’янець-Подільський',
        value: 'Кам’янець-Подільський',
        region: 'Хмельницька'
    },
    {
        text: 'Хмельницький',
        value: 'Хмельницький',
        region: 'Хмельницька'
    },
    {
        text: 'Шепетівський',
        value: 'Шепетівський',
        region: 'Хмельницька'
    },
    {
        text: 'Звенигородський',
        value: 'Звенигородський',
        region: 'Черкаська'
    },
    {
        text: 'Золотоніський',
        value: 'Золотоніський',
        region: 'Черкаська'
    },
    {
        text: 'Уманський',
        value: 'Уманський',
        region: 'Черкаська'
    },
    {
        text: 'Черкаський',
        value: 'Черкаський',
        region: 'Черкаська'
    },
    {
        text: 'Вижницький',
        value: 'Вижницький',
        region: 'Чернівецька'
    },
    {
        text: 'Дністровський',
        value: 'Дністровський',
        region: 'Чернівецька'
    },
    {
        text: 'Чернівецький',
        value: 'Чернівецький',
        region: 'Чернівецька'
    },
    {
        text: 'Корюківський',
        value: 'Корюківський',
        region: 'Чернігівська'
    },
    {
        text: 'Ніжинський',
        value: 'Ніжинський',
        region: 'Чернігівська'
    },
    {
        text: 'Новгород-Сіверський',
        value: 'Новгород-Сіверський',
        region: 'Чернігівська'
    },
    {
        text: 'Прилуцький',
        value: 'Прилуцький',
        region: 'Чернігівська'
    },
    {
        text: 'Чернігівський',
        value: 'Чернігівський',
        region: 'Чернігівська'
    },
    {
        text: 'Білогірський',
        value: 'Білогірський',
        region: 'АР Крим'
    },
    {
        text: 'Джанкойський',
        value: 'Джанкойський',
        region: 'АР Крим'
    },
    {
        text: 'Євпаторійський',
        value: 'Євпаторійський',
        region: 'АР Крим'
    },
    {
        text: 'Керченський',
        value: 'Керченський',
        region: 'АР Крим'
    },
    {
        text: 'Курманський',
        value: 'Курманський',
        region: 'АР Крим'
    },
    {
        text: 'Перекопський',
        value: 'Перекопський',
        region: 'АР Крим'
    },
    {
        text: 'Сімферопольський',
        value: 'Сімферопольський',
        region: 'АР Крим'
    },
    {
        text: 'Феодосійський',
        value: 'Феодосійський',
        region: 'АР Крим'
    },
    {
        text: 'Ялтинський',
        value: 'Ялтинський',
        region: 'АР Крим'
    },
]

const regionSelect = [
    {value: 'Автономна Республіка Крим', text: 'Автономна Республіка Крим'},
    {value: 'Вінницька', text: 'Вінницька'},
    {value: 'Волинська', text: 'Волинська'},
    {value: 'Дніпропетровська', text: 'Дніпропетровська'},
    {value: 'Донецька', text: 'Донецька'},
    {value: 'Житомирська', text: 'Житомирська'},
    {value: 'Закарпатська', text: 'Закарпатська'},
    {value: 'Запорізька', text: 'Запорізька'},
    {value: 'Івано-Франківська', text: 'Івано-Франківська'},
    {value: 'Київська', text: 'Київська'},
    {value: 'Кіровоградська', text: 'Кіровоградська'},
    {value: 'Луганська', text: 'Луганська'},
    {value: 'Львівська', text: 'Львівська'},
    {value: 'Миколаївська', text: 'Миколаївська'},
    {value: 'Одеська', text: 'Одеська'},
    {value: 'Полтавська', text: 'Полтавська'},
    {value: 'Рівненська', text: 'Рівненська'},
    {value: 'Сумська', text: 'Сумська'},
    {value: 'Тернопільська', text: 'Тернопільська'},
    {value: 'Харківська', text: 'Харківська'},
    {value: 'Херсонська', text: 'Херсонська'},
    {value: 'Хмельницька', text: 'Хмельницька'},
    {value: 'Черкаська', text: 'Черкаська'},
    {value: 'Чернівецька', text: 'Чернівецька'},
    {value: 'Чернігівська', text: 'Чернігівська'},
]

const contactTypeSelect = [
    {text: 'Телефон', value: 'phone'},
    {text: 'Email', value: 'email'}
]

const ext_obj = [
    {ext: 'aac', mime: 'audio/aac', icon: 'mdi-file-music-outline'},
    {ext: 'abw', mime: 'application/x-abiword'},
    {ext: 'arc', mime: 'application/x-freearc'},
    {ext: 'avi', mime: 'video/x-msvideo', icon: 'mdi-filmstrip-box'},
    {ext: 'azw', mime: 'application/vnd.amazon.ebook'},
    {ext: 'bin', mime: 'application/octet-stream'},
    {ext: 'bmp', mime: 'image/bmp', icon: 'mdi-file-image-outline'},
    {ext: 'bz', mime: 'application/x-bzip'},
    {ext: 'bz2', mime: 'application/x-bzip2'},
    {ext: 'csh', mime: 'application/x-csh'},
    {ext: 'css', mime: 'text/css', icon: 'mdi-language-css3'},
    {ext: 'csv', mime: 'text/csv', icon: 'mdi-file-document'},
    {ext: 'doc', mime: 'application/msword', icon: 'mdi-file-word-outline'},
    {
        ext: 'docx',
        mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        icon: 'mdi-file-word-outline'
    },
    {
        ext: 'dotx',
        mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        icon: 'mdi-file-word-outline'
    },
    {ext: 'eot', mime: 'application/vnd.ms-fontobject'},
    {ext: 'epub', mime: 'application/epub+zip'},
    {ext: 'gz', mime: 'application/gzip'},
    {ext: 'gif', mime: 'image/gif', icon: 'mdi-gif'},
    {ext: 'htm', mime: 'text/html', icon: 'mdi-language-html5'},
    {ext: 'html', mime: 'text/html', icon: 'mdi-language-html5'},
    {ext: 'ico', mime: 'image/vnd.microsoft.icon', icon: 'mdi-simple-icons'},
    {ext: 'ics', mime: 'text/calendar'},
    {ext: 'jar', mime: 'application/java-archive'},
    {ext: 'jpeg', mime: '', icon: 'mdi-file-image-outline'},
    {ext: 'jpg', mime: 'image/jpeg', icon: 'mdi-file-image-outline'},
    {ext: 'json', mime: 'application/json'},
    {ext: 'jsonld', mime: 'application/ld+json'},
    {ext: 'mid', mime: 'audio/midi audio/x-midi'},
    {ext: 'midi', mime: 'audio/midi audio/x-midi'},
    {ext: 'mjs', mime: 'text/javascript'},
    {ext: 'mp3', mime: 'audio/mpeg', icon: 'mdi-file-music-outline'},
    {ext: 'cda', mime: 'application/x-cdf'},
    {ext: 'mp4', mime: 'video/mp4', icon: 'mdi-filmstrip-box'},
    {ext: 'mpeg', mime: 'video/mpeg', icon: 'mdi-filmstrip-box'},
    {ext: 'mpkg', mime: 'application/vnd.apple.installer+xml'},
    {ext: 'odp', mime: 'application/vnd.oasis.opendocument.presentation', icon: 'mdi-book-open-outline'},
    {ext: 'ods', mime: 'application/vnd.oasis.opendocument.spreadsheet', icon: 'mdi-book-open-outline'},
    {ext: 'odt', mime: 'application/vnd.oasis.opendocument.text', icon: 'mdi-book-open-outline'},
    {ext: 'oga', mime: 'audio/ogg'},
    {ext: 'ogv', mime: 'video/ogg'},
    {ext: 'ogx', mime: 'application/ogg'},
    {ext: 'opus', mime: 'audio/opus'},
    {ext: 'otf', mime: 'font/otf'},
    {ext: 'png', mime: 'image/png', icon: 'mdi-file-image-outline'},
    {ext: 'pdf', mime: 'application/pdf', icon: 'mdi-file-pdf-outline'},
    {ext: 'php', mime: 'application/x-httpd-php'},
    {ext: 'ppt', mime: 'application/vnd.ms-powerpoint', icon: 'mdi-file-document-multiple-outline'},
    {
        ext: 'pptx',
        mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        icon: 'mdi-file-document-multiple-outline'
    },
    {ext: 'rar', mime: 'application/vnd.rar'},
    {ext: 'rtf', mime: 'application/rtf'},
    {ext: 'sh', mime: 'application/x-sh'},
    {ext: 'svg', mime: 'image/svg+xml', icon: 'mdi-svg'},
    {ext: 'swf', mime: 'application/x-shockwave-flash'},
    {ext: 'tar', mime: 'application/x-tar', icon: 'mdi-folder-zip-outline'},
    {ext: 'tif', mime: 'image/tiff', icon: 'mdi-file-image-outline'},
    {ext: 'tiff', mime: 'image/tiff', icon: 'mdi-file-image-outline'},
    {ext: 'ts', mime: 'video/mp2t', icon: 'mdi-filmstrip-box'},
    {ext: 'ttf', mime: 'font/ttf'},
    {ext: 'txt', mime: 'text/plain', icon: 'mdi-format-align-justify'},
    {ext: 'vsd', mime: 'application/vnd.visio'},
    {ext: 'wav', mime: 'audio/wav'},
    {ext: 'weba', mime: 'audio/webm'},
    {ext: 'webm', mime: 'video/webm'},
    {ext: 'webp', mime: 'image/webp'},
    {ext: 'woff', mime: 'font/woff'},
    {ext: 'woff2', mime: 'font/woff2'},
    {ext: 'xhtml', mime: 'pplication/xhtml+xml'},
    {ext: 'xls', mime: 'application/vnd.ms-excel', icon: 'mdi-file-excel-outline'},
    {
        ext: 'xlsx',
        mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        icon: 'mdi-file-excel-outline'
    },
    {ext: 'xml', mime: 'application/xml'},
    {ext: 'text/xml', mime: ''},
    {ext: 'xul', mime: 'application/vnd.mozilla.xul+xml'},
    {ext: 'zip', mime: 'application/zip', icon: 'mdi-folder-zip-outline'},
    {ext: '3gp', mime: 'video/3gpp'},
    {ext: '3g2', mime: 'video/3gpp2'},
    {ext: '7z', mime: 'application/x-7z-compressed', icon: 'mdi-folder-zip-outline'},
    {ext: 'dbf', mime: 'application/dbase', icon: 'mdi-file-table-outline'},
    {ext: 'DBF', mime: 'application/dbase', icon: 'mdi-file-table-outline'}
]

const split_type_select = [
    {text: 'Поділ на усі послуги', value: 'all_service'},
    {text: 'Не розділяти', value: 'dont_split'},
]

const appeal_status_type = [
    {text: 'Нове', value: 'new', color: 'teal', icon: 'mdi-archive-plus-outline', border: 'teal'},
    {
        text: 'В процесі',
        value: 'progress',
        color: 'secondary',
        icon: 'mdi-archive-sync-outline',
        border: 'var(--v-secondary-base)'
    },
    {
        text: 'Виконано',
        value: 'done',
        color: 'success',
        icon: 'mdi-archive-check-outline',
        border: 'var(--v-success-base)'
    },
    {
        text: 'Відхилено',
        value: 'reject',
        color: 'error',
        icon: 'mdi-archive-remove-outline',
        border: 'var(--v-error-base)'
    },
    {
        text: 'Повторно',
        value: 'repeat',
        color: 'primary',
        icon: 'mdi-archive-cog-outline',
        border: 'var(--v-primary-base)'
    },
    {text: 'Закрито', value: 'closed', color: 'pink lighten-1', icon: 'mdi-archive-lock-outline', border: 'purple'}
]

const appeal_status_type_admin = [
    {text: 'В процесі', value: 'progress', color: 'secondary', icon: 'mdi-archive-sync-outline'},
    {text: 'Виконано', value: 'done', color: 'success', icon: 'mdi-archive-check-outline'},
    {text: 'Відхилено', value: 'reject', color: 'error', icon: 'mdi-archive-remove-outline'},
]

const flat_note_type_select = [
    {text: 'Дзвінок', value: 'default_call', color: 'teal', icon: 'mdi-cellphone'},
    {text: 'Дзвінок (попередження)', value: 'warning_call', color: 'error', icon: 'mdi-cellphone'},
    {text: 'Попередження', value: 'warning_document', color: 'error lighten-1', icon: 'mdi-text-box-outline'},
    {text: 'Проблемний', value: 'problem_person', color: 'secondary', icon: 'mdi-account-alert-outline'},
    {text: 'Коментар', value: 'comment', color: 'secondary', icon: 'mdi-comment-text-outline'},
    {text: 'СМС', value: 'message', color: 'teal', icon: 'mdi-cellphone-message'},
]

const flat_note_result_type_select = [
    {text: 'Позитивно', value: 'positive', color: 'success', icon: 'mdi-archive-sync-outline'},
    {text: 'Негативно', value: 'negative', color: 'error lighten-1', icon: 'mdi-archive-check-outline'},
    {text: 'Нейтрально', value: 'neutral', color: 'secondary', icon: 'mdi-archive-remove-outline'},
    {text: 'Сердито', value: 'angry', color: 'error', icon: 'mdi-archive-remove-outline'},
    {text: 'Згодом', value: 'later', color: 'teal', icon: 'mdi-archive-remove-outline'},
]

const court_case_document_type_select = [
    {text: 'Виконавчий лист', value: 'writ_of_execution', color: 'teal', icon: 'text-box-outline'},
    {text: 'Заява про видачу СН', value: 'application_for_a_court_order', color: 'error', icon: 'text-box-outline'},
    {text: 'Позовна заява', value: 'statement_of_claim', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Рішення виконавчого комітету', value: 'decisions_of_the_executive_committee',
        color: 'secondary', icon: 'text-box-outline'},
    {text: 'Рішення суду', value: 'courts_decision', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Судовий наказ', value: 'court_order', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Ухвала', value: 'resolution', color: 'error lighten-1', icon: 'text-box-outline'}
]

const court_case_table_type_select = [
    {text: 'Претензія', value: 'pretense'},
    {text: 'Судова справа', value: 'court_case'},
]

const court_case_resolution_type_select = [
    {text: 'Повністю', value: 'fully', color: 'teal', icon: 'text-box-outline'},
    {text: 'Частково', value: 'partially', color: 'error', icon: 'text-box-outline'},
    {text: 'Відмовлено', value: 'denied', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'На розгляді', value: 'under_consideration', color: 'error lighten-1', icon: 'text-box-outline'}
]

const court_case_stage_of_enforcement_type_select = [
    {text: 'Виконано', value: 'performed', color: 'teal', icon: 'text-box-outline'},
    {text: 'Не виконано', value: 'not_done', color: 'error', icon: 'text-box-outline'},
    {text: 'Виконано частково', value: 'partially_completed', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Задоволено', value: 'satisfied', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Не задоволено', value: 'not_satisfied', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Задоволено частково', value: 'partially_satisfied', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Відкрито', value: 'open', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Закінчено', value: 'finished', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Повернено', value: 'returned', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Відмовлено', value: 'denied', color: 'error lighten-1', icon: 'text-box-outline'},
]

const pretense_status_select = [
    {text: 'Створено', value: 'pretense_create', color: 'teal', icon: 'text-box-outline'},
    {text: 'Відправлено звичайним листом', value: 'send_general_letter', color: 'error', icon: 'text-box-outline'},
    {text: 'Відправлено заказним листом', value: 'send_order_letter', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Одержано', value: 'received', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Повернено', value: 'returned', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Оплачено', value: 'payed', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Підготовлено для суду', value: 'prepared_for_court_case', color: 'error lighten-1', icon: 'text-box-outline'},
    {text: 'Закрито', value: 'closed', color: 'error lighten-1', icon: 'text-box-outline'},
]

const desktop_tree_type_select = [
    {text: 'Населений->Вулиця', value: 'tree_1'},
    {text: 'Населений->Вулиця->Будинок', value: 'tree_2'},
    {text: 'Населений->Вулиця->Будинок->Під’їзд', value: 'tree_3'},
    {text: 'Населений->Вид житла->Вулиця', value: 'tree_4'},
    {text: 'Населений->Вид житла->Вулиця->Будинок', value: 'tree_5'},
    {text: 'Обліковець->Населений->Вулиця', value: 'tree_6'},
    {text: 'Обліковець->Населений->Вулиця->Будинок', value: 'tree_7'},
    {text: 'Вид житла->Населений->Вулиця', value: 'tree_8'},
    {text: 'Вид житла->Населений->Вулиця->Будинок', value: 'tree_9'},
    {text: 'Громада->Населений->Район населеного->Вулиця', value: 'tree_10'},
]

const warehouse_income_operation = [
    {text: 'Покупка, комісія', value: 'buy_or_commission'},
    {text: 'Устаткування', value: 'equipment'},
    {text: 'У перереробку', value: 'in_processing'},
    {text: 'Об\'єкти будівництва', value: 'construction_objects'},
]

const cash_order_document_income_operation = [
    {text: 'Оплата від покупця', value: 'pay_from_customer'},
    {text: 'Повернення коштів від постачальника', value: 'return_pay_from_provider'},
    {text: 'Повернення коштів від підзвітної особи', value: 'return_from_account_person'},
    {text: 'Повернення заробітної плати', value: 'return_from_salary_person'},
    {text: 'Одержання готівки в банку', value: 'cash_get_from_bank'},
    {text: 'Інші розрахунки із контрагентами', value: 'other_pay_from_contractor'},
    {text: 'Інше надходження коштів', value: 'other_pay_income'},
]

const warehouse_outcome_type = [
    {text: 'ФІФО', value: 'fifo'},
    {text: 'По середньому', value: 'average'},
]


const entering_balances_operation = [
    {text: 'Товари, матеріали, МШП на складі', value: 'materials_goods_low_cost_in_warehouse'},
    {text: 'Розрахунки із покупцями та постачальниками', value: 'provider_and_customer'},
    {text: 'Банківські операції', value: 'bank_operation'},
    {text: 'Касові операції', value: 'cash_operation'},
    {text: 'Інвестиції ОС та МШП', value: 'fixed_and_low_cost_invest'},
    {text: 'Заробітна плата', value: 'salary'},
    {text: 'Інше', value: 'other'},
]

const commissioning_document_operation = [
    {text: 'Введення в експлуатацію', value: 'fixed_asset'},
    {text: 'За рез. інвентаризації', value: 'inventory'}
]

const bank_document_income_operation = [
    {text: 'Оплата від покупця', value: 'pay_from_customer'},
    {text: 'Повернення коштів від постачальника', value: 'return_pay_from_provider'},
    {text: 'Повернення коштів від підзвітної особи', value: 'return_from_account_person'},
    {text: 'Повернення заробітної плати', value: 'return_from_salary_person'},
    {text: 'Інкасація', value: 'bank_collection'},
    {text: 'Надходження від ФСС', value: 'income_from_social'},
    {text: 'Інші розрахунки із контрагентами', value: 'other_pay_from_contractor'},
    {text: 'Інше надходження коштів', value: 'other_pay_income'},
]

const update_information_module_type = [
    { text: 'Поквартирний облік', value: 'flat_accounting' },
    { text: 'Поквартирний облік (юр. особи)', value: 'flat_legacy_accounting' },
    { text: 'Бухгалтерський облік', value: 'accounting' }
]

const update_information_status = [
    { text: 'Критичне', value: 'critical', color: 'error darken-1', icon: 'mdi-account' },
    { text: 'Попередження', value: 'warning', color: 'error lighten-1', icon: 'mdi-account' },
    { text: 'Звичайне', value: 'usual', color: 'secondary', icon: 'mdi-account' },
    { text: 'Інформування', value: 'simple', color: 'success', icon: 'mdi-account' },
    { text: 'Виправлення помилок', value: 'bug', color: 'teal', icon: 'mdi-account' },
]

const registration_of_changes_section_type = [
    { text: 'Нормативні параметри', value: 'flat_normative'},
    { text: 'Послуги', value: 'flat_services'},
    { text: 'Лічильники', value: 'flat_counter'},
    { text: 'Приналежність до буд. ліч.', value: 'building_counter_user'},
    { text: 'Прилади обліку', value: 'appliances'},
    { text: 'Інше', value: 'other'},
]

const tax_bill_document_income_operation = [
    {text: 'Податкова накладна', value: 'tax_bill'},
    {text: 'Розрахунок коригування (повернення)', value: 'adjustment_calculation_return'},
    {text: 'Розрахунок коригування (зміна суми компенсації)', value: 'adjustment_calculation_change_sum'},
    {text: 'Відновлення податкового кредиту', value: 'tax_credit_recovery'},
    {text: 'Виправлення помилки', value: 'error_correction'},
    {text: 'Інше (товарний чек і т.п.)', value: 'other'},
]

const tax_bill_document_outcome_operation = [
    {text: 'Оподатковувані операції', value: 'taxable_operation'},
    {text: 'Звільнені від оподаткування', value: 'tax_exempted'},
    {text: 'Умовний продаж', value: 'conditional_sale'},
    {text: 'Умовний продаж при списанні ОС', value: 'conditional_sale_write_off_fixed_assets'},
]

const tax_bill_consolidate_type = [
    {text: '0 - не є зведеною', value: 'CODE_0'},
    {text: '1 - 198.5 умовний продаж', value: 'CODE_1'},
    {text: '2 - 199.1 частковий умовний продаж', value: 'CODE_2'},
    {text: '3 - особ. запов. п. 15 Порядку (перевищення бази)', value: 'CODE_3'},
    {text: '4 - особ. запов. п. 19 Порядку (ритмічний або безперервний характер)', value: 'CODE_4'},
]

const tax_bill_spec_mode = [
    {text: '0 - не використ.', value: 'not_use'},
    {text: '2 - плат. вкл. до реє. отрим. бюдж. дотації', value: 'budget_subsidy'},
]

const tax_bill_not_issue_type = [
    {text: '0 - видається покупцю', value: 'CODE_00'},
    {text: '1 - склад. на суму збільшення комп. вартості поставки', value: 'CODE_01'},
    {text: '2 - не платнику податку', value: 'CODE_02'},
    {text: '3 - натур. виплата в рах. оплати праці фіз.особам', value: 'CODE_03'},
    {text: '4 - у межаж балансу для невироб.використ.', value: 'CODE_04'},
    {text: '5 - ліквідація ОФ за самост.ріш. платника', value: 'CODE_05'},
    {text: '6 - переведення ОФ до складу невиробничих', value: 'CODE_06'},
    {text: '7 - експортні постачання', value: 'CODE_07'},
    {text: '8 - операції, які не є об’єктом оподат. ПДВ', value: 'CODE_08'},
    {text: '9 - операції, які звільнені від оподаткування ПДВ', value: 'CODE_09'},
    {text: '10 - визнання умов.пост.зал. товарів', value: 'CODE_10'},
    {text: '11 - за щоденними підсумками операцій', value: 'CODE_11'},
    {text: '12 - не платнику ПДВ із вказанняи його назви', value: 'CODE_12'},
    {text: '13 - використ. засобів не у госп. діяльності', value: 'CODE_13'},
    {text: '14 - від нерезидента', value: 'CODE_14'},
]

const tax_bill_calculated_form = [
    {text: 'Готівкова форма', value: 'cash'},
    {text: 'Банківський рахунок', value: 'bank'},
    {text: 'Бартер', value: 'barter'},
    {text: 'Чек', value: 'receipt'},
]

const tax_bill_contract_type = [
    { value: 'DBK', text: 'Договір бартерної поставки'},
    { value: 'DVZ', text: 'Договір відповідального зберігання'},
    { value: 'DDU', text: 'Договір довірчого управління'},
    { value: 'DDO', text: 'Договір доручення'},
    { value: 'DKO', text: 'Договір комісії'},
    { value: 'DKG', text: 'Договір консигнації'},
    { value: 'DKP', text: 'Договір купівлі-продажу'},
    { value: 'DOR', text: 'Договір оренди'},
    { value: 'DPO', text: 'Договір поруки'},
    { value: 'DPK', text: 'Договір поставки'},
    { value: 'DPP', text: 'Договір поставки оптової партії'},
    { value: 'DVR', text: 'Договір про виконання роботи'},
    { value: 'DID', text: 'Договір про інформаційно-довідкове обслуговування'},
    { value: 'DPL', text: 'Договір про надання посередницьких послуг'},
    { value: 'DNP', text: 'Договір про надання послуг'},
    { value: 'DSZ', text: 'Договір складського зберігання'},
]

const tax_bill_table_code_type = [
    {text: 'УКТ ЗЕД', value: 'CODE_UKTZ'},
    {text: 'ДКПП', value: 'CODE_DKPP'},
]

const tax_bill_document_type = [
    {text: 'ПН (Податкова накладна)', value: 'PN'},
    {text: 'МД (Вантажна митна декларація)', value: 'MD'},
    {text: 'РК (Розрахунок корегування)', value: 'RK'},
    {text: 'ЧК (Касовий чек)', value: 'KC'},
    {text: 'ТК (Транспортний квиток)', value: 'TK'},
    {text: 'ГР (Готельний рахунок)', value: 'HR'},
    {text: 'ПЗ (Розрахунок за послуги зв’язку)', value: 'PZ'},
    {text: 'ПО (Послуги, вартість яких по даним приладів обліку)', value: 'PO'},
    {text: '- (Бухгалтерстька довідка (інше))', value: 'BO'},
]

const pdv_items = [
    {text: 'Без ПДВ', value: 'pdv_without'},
    {text: 'ПДВ 0%', value: 'pdv_0'},
    {text: 'ПДВ 5%', value: 'pdv_5'},
    {text: 'ПДВ 7%', value: 'pdv_7'},
    {text: 'ПДВ 20%', value: 'pdv_20'},
]

const salary_to_pay_operation = [
    {text: 'Аванс (розрахований)', value: 'advance_calculated'},
    {text: 'Аванс (фіксований)', value: 'advance_fixed'},
    {text: 'Виплата заробітної плати', value: 'salary'},
    {text: 'Виплата лікарняних', value: 'hospital'},
    {text: 'Лікарняні (без розрахунку)', value: 'advance_hospital'},
    {text: 'Відпустка (без розрахунку)', value: 'advance_vocation'},
    {text: 'Виплата ПДФО', value: 'pdfo'},
    {text: 'Виплата внесків', value: 'contribution'},
    {text: 'Виплата податків', value: 'taxes'},
]

const hospital_type = [
    {text: 'Загальне захворювання', value: 'general_hospital', days_by_organization: 5},
    {text: 'По догляду за дитиною', value: 'babe_care_hospital', days_by_organization: 0},
    {text: 'Допомога без захворювання', value: 'help_without_hospital', days_by_organization: 0},
    {text: 'Професійне захворювання', value: 'professional_seek', days_by_organization: 5},
    {text: 'Травма на виробництві', value: 'heat_on_work_hospital', days_by_organization: 5},
    {text: 'Невиробнича травма', value: 'non_heat_work_hospital', days_by_organization: 5},
    {text: 'Ізоляція (COVID-19)', value: 'covid_hospital', days_by_organization: 5},
    {text: 'Інше', value: 'other_hospital', days_by_organization: 0},
]

const vocation_type = [
    {text: 'Відпустка осн.щорічна', value: 'vocation_main', accrual: 'vocation_main'},
    {text: 'Компенсація відпустки', value: 'vocation_compensation', accrual: 'vocation_compensation'},
    {text: 'Відпустка б/о', value: 'vocation_without', accrual: 'vocation_without'},
    {text: 'Відпустка б/о інші', value: 'vocation_without_other', accrual: 'vocation_without_other'},
    {text: 'Відпустка додаткова', value: 'vocation_additional', accrual: 'additional_main_vocation'},
    {text: 'Відпустка додаткова б/о', value: 'vocation_additional_without', accrual: 'vocation_without_other'},
    {text: 'Відпустка чорнобильська', value: 'vocation_chornobel', accrual: 'chornobel_vocation'},
    {text: 'Відпустка творча', value: 'vocation_creative', accrual: 'study_vocation'},
    {text: 'Відпустка на навчання', value: 'vocation_study', accrual: 'study_vocation'},
    {text: 'Відпустка на навчання б/о', value: 'vocation_study_without', accrual: 'study_vocation_without'},
    {text: 'Відпустка на дітей', value: 'vocation_children', accrual: 'vocation_children'},
    {text: 'Відпустка по вагітності', value: 'vocation_pregnancy', accrual: 'vocation_pregnancy'},
    {text: 'Відпустка по дог. за дит.', value: 'vocation_babysitting', accrual: 'vocation_pregnancy_without'},
]

const vocation_type_accrual = {
    vocation_main: 'vocation_main',
    vocation_compensation: 'vocation_compensation',
    vocation_without: 'vocation_without',
    vocation_without_other: 'vocation_without_other',
    vocation_additional: 'additional_main_vocation',
    vocation_additional_without: 'vocation_without_other',
    vocation_chornobel: 'chornobel_vocation',
    vocation_creative: 'study_vocation',
    vocation_study: 'study_vocation',
    vocation_study_without: 'study_vocation_without',
    vocation_children: 'vocation_children',
    vocation_pregnancy: 'vocation_pregnancy',
    vocation_babysitting: 'vocation_pregnancy_without'
}

const contract_pay_type_select = [
    {text: 'Щомісячно', value: 'every_month'},
    {text: 'В кінці періоду', value: 'end_of_period'},
]

const work_transfer_accrual_type = [
    {text: 'Почати', value: 'start'},
    {text: 'Припинити', value: 'end'},
    {text: 'Змінити', value: 'change'},
    {text: 'Без змін', value: 'unchanged'},
]

const accrual_type_select = [
    {text: 'Основне нарахування', value: 'main'},
    {text: 'Другорядне нарахування', value: 'secondary'},
]

const accrual_tax_group_select = [
    {text: 'Основні/базові', value: 'main'},
    {text: 'Лікарняні', value: 'hospital'},
    {text: 'Декретні', value: 'decree'},
    {text: 'По договору ЦПХ', value: 'by_contract'},
]

const accrual_count_type_select = [
    {text: 'По міс.ставці в днях', value: 'by_days'},
    {text: 'По міс.ставці в днях', value: 'by_hours'},
    {text: 'По середньому', value: 'by_average'},
    {text: 'Фіксований розмір', value: 'by_fixed'},
    {text: 'Відсотком', value: 'by_percent'},
    {text: 'По денній тар.ставці', value: 'by_day_tariff'},
    {text: 'По годинній тар.ставці', value: 'by_hour_tariff'},
    {text: 'Без розрахунку', value: 'not_calc'},
]

const accrual_group_select = [
    {text: 'Основна з/п', value: 'main'},
    {text: 'Лік. (підприємство)', value: 'hospital_by_organization'},
    {text: 'Лік. (фонд соц.страх.)', value: 'hospital_by_social'},
    {text: 'Лік (дикретні)', value: 'decree'},
    {text: 'Відпускні', value: 'vocation'},
]

const appliance_type_select = [
    {text: 'Газова плита', value: 'gas_stove', icon: 'mdi-fire-circle', color: 'secondary'},
    {text: 'Кухонне вогнище', value: 'kitchen_stove', icon: 'mdi-stove', color: 'error lighten-1'},
    {text: 'Газова колонка', value: 'gas_water_heating', icon: 'mdi-water-boiler', color: 'primary'},
    {text: 'Газовий котел (без колонки)', value: 'gas_boiler_without_water_heating', icon: 'mdi-meter-gas', color: 'teal'},
    {text: 'Газовий котел (з колонкою)', value: 'gas_boiler_with_water_heating', icon: 'mdi-meter-gas-outline', color: 'teal darken-1'},
    {text: 'Опалювальне вогнище', value: 'heating_furnace', icon: 'mdi-fire', color: 'orange darken-1'},
    {text: 'ГВП (наявність)', value: 'hot_water', icon: 'mdi-water-thermometer', color: 'error'},
]

const verification_operation_type_select = [
    {text: 'Знято на повірку', value: 'verification_start', icon: 'mdi-lock-outline', color: 'error'},
    {text: 'Встанов. з повірки', value: 'verification_end', icon: 'mdi-lock-open-variant-outline', color: 'success'},
    {text: 'Встановлено', value: 'installation', icon: 'mdi-plus-box-outline', color: 'success'},
    {text: 'Знято', value: 'removal', icon: 'mdi-minus-box-outline', color: 'error'},
]

const appliance_operation_type_select = [
    {text: 'Встановлено', value: 'installation', icon: 'mdi-plus-box-outline', color: 'success'},
    {text: 'Знято', value: 'removal', icon: 'mdi-minus-box-outline', color: 'error'},
    {text: 'Опломбовано', value: 'sealing', icon: 'mdi-lock-outline', color: 'error'},
    {text: 'Розпломбовано', value: 'unsealing', icon: 'lock-open-variant-outline', color: 'success'},
]

const get_accrual_type = (payload) => {
    return (accrual_type_select.find(i => i.value === payload) || {}).text
}

const get_hospital_type = (payload, col = 'text') => {
    return (hospital_type.find(i => i.value === payload) || {})[col]
}


const getFlatNoteType = (payload, col = 'text') => {
    return (flat_note_type_select.find(i => i.value === payload) || {})[col]
}

const getFlatNoteResultType = (payload, col = 'text') => {
    const item = (flat_note_result_type_select.find(i => i.value === payload) ||
        {color: 'secondary', icon: 'mdi-account', text: 'Відсутній'})
    return item[[col]]
}

const get_vocation_type = (payload, col = 'text') => {
    return (vocation_type.find(i => i.value === payload) || {})[col]
}

const get_salary_to_pay = (payload) => {
    return (salary_to_pay_operation.find(i => i.value === payload) || {}).text
}

const getRegistrationOfChangesSectionType = (payload) => {
    return (registration_of_changes_section_type.find(i => i.value === payload) || {}).text
}

const get_accrual_tax_group = (payload) => {
    return (accrual_tax_group_select.find(i => i.value === payload) || {}).text
}

const getCourtCaseStageOfEnforcement = (payload, col = 'text') => {
    return (court_case_stage_of_enforcement_type_select.find(i => i.value === payload) || {})[col]
}

const getCourtCaseDocument = (payload, col = 'text') => {
    return (court_case_document_type_select.find(i => i.value === payload) || {})[col]
}

const getPretenseStatus = (payload, col = 'text') => {
    return (pretense_status_select.find(i => i.value === payload) || {})[col]
}

const bank_document_outcome_operation = [
    {text: 'Оплата постачальнику', value: 'pay_from_provider'},
    {text: 'Повернення коштів від покупця', value: 'return_pay_from_customer'},
    {text: 'Інші розрахунки із контрагентами', value: 'other_pay_from_contractor'},
    {text: 'Перерахування коштів підзвітній особі', value: 'enumeration_to_account_person'},
    {text: 'Перерахування заробітної плати', value: 'enumeration_salary'},
    {text: 'Перерахування/сплата податків', value: 'enumeration_tax'},
    {text: 'Інше списання коштів', value: 'other_pay_outcome'},
]

const cash_order_document_outcome_operation = [
    {text: 'Оплата постачальнику', value: 'pay_from_provider'},
    {text: 'Повернення коштів від покупця', value: 'return_pay_from_customer'},
    {text: 'Інші розрахунки із контрагентами', value: 'other_pay_from_contractor'},
    {text: 'Перерахування коштів підзвітній особі', value: 'enumeration_to_account_person'},
    {text: 'Перерахування заробітної плати', value: 'enumeration_salary'},
    {text: 'Перерахування/сплата податків', value: 'enumeration_tax'},
    {text: 'Інкасація', value: 'bank_collection'},
    {text: 'Внесення готівки у банк', value: 'cash_put_to_bank'},
    {text: 'Інше списання коштів', value: 'other_pay_outcome'},
]

const advance_report_document_type = [
    {text: 'Платіжне доручення вихідне', value: 'DOCUMENT_BANK_OUTCOME',},
    {text: 'Видатковий касовий ордер', value: 'DOCUMENT_CASH_ORDER_OUTCOME'},
]

const tax_bill_income_document_type = [
    {text: 'Акт викон. робіт, наданих послуг (вхідний)', value: 'DOCUMENT_ACT_WORK_PERFORMED_INCOME'},
    {text: 'Авансовий звіт', value: 'DOCUMENT_ADVANCE_REPORT'},
    {text: 'Платіжне доручення вихідне', value: 'DOCUMENT_BANK_OUTCOME',},
    {text: 'Видатковий касовий ордер', value: 'DOCUMENT_CASH_ORDER_OUTCOME'},
    {text: 'Прихідна накладна', value: 'DOCUMENT_INVOICE_WAREHOUSE_INCOME'},
]

const tax_bill_outcome_document_type = [
    {text: 'Акт викон. робіт, наданих послуг (вхідний)', value: 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME'},
    {text: 'Платіжне доручення вхідне', value: 'DOCUMENT_BANK_INCOME',},
    {text: 'Прибутковий касовий ордер', value: 'DOCUMENT_CASH_ORDER_INCOME'},
    {text: 'Видаткова накладна', value: 'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME'},
]

const personal_timesheet_document_fill_type = [
    {text: 'Повний місяць', value: 'by_month'},
    {text: 'Перша половина місяця', value: 'by_first_part'},
    {text: 'Друга половина місяця', value: 'by_second_part'},
    {text: 'Довільна дата', value: 'by_custom_date'},
]

const fixed_assets_group = [
    {text: 'Група 1: Земельні ділянки', value: 'group_101', months: 0},
    {text: 'Група 2: Капітальні витрати по поліпшенню земель', value: 'group_102', months: 180},
    {text: 'Група 3: Будинки та спорудження', value: 'group_103', months: 180},
    {text: 'Група 4: Машини й устаткування', value: 'group_104', months: 60},
    {text: 'Група 5: Транспортні засоби', value: 'group_105', months: 60},
    {text: 'Група 6: Інструменти, прилади та інвентар', value: 'group_106', months: 48},
    {text: 'Група 7: Тварини', value: 'group_107', months: 72},
    {text: 'Група 8: Багаторічні насадження', value: 'group_108', months: 120},
    {text: 'Група 9: Інші основні засоби', value: 'group_109', months: 144},
    {text: 'Група 10: Бібліотечні фонди', value: 'group_110', months: 0},
    {text: 'Група 11: Малоцінні необоротні матеріальні активи', value: 'group_111', months: 0},
    {text: 'Група 12: Тимчасові (нетитульні) спорудження', value: 'group_112', months: 60},
    {text: 'Група 13: Природні ресурси', value: 'group_113', months: 0},
    {text: 'Група 14: Інвентарна тара', value: 'group_114', months: 72},
    {text: 'Група 15: Предмети прокату', value: 'group_115', months: 60},
    {text: 'Група 16: Довгострокові біологічні активи', value: 'group_116', months: 84},
]

const fixed_assets_group_only_fixed = [
    {text: 'Група 1: Земельні ділянки', value: 'group_101'},
    {text: 'Група 2: Капітальні витрати по поліпшенню земель', value: 'group_102'},
    {text: 'Група 3: Будинки та спорудження', value: 'group_103'},
    {text: 'Група 4: Машини й устаткування', value: 'group_104'},
    {text: 'Група 5: Транспортні засоби', value: 'group_105'},
    {text: 'Група 6: Іінструменти, прилади та інвентар', value: 'group_106'},
    {text: 'Група 7: Тварини', value: 'group_107'},
    {text: 'Група 8: Багаторічні насадження', value: 'group_108'},
    {text: 'Група 9: Інші основні засоби', value: 'group_109'},
]

const fixed_assets_group_only_low_cost = [
    {text: 'Група 11: Малоцінні необоротні матеріальні активи', value: 'group_112'},
]

const amortisation_calc_type_fixed = [
    {text: 'Прямолінійний', value: 'straight'},
    {text: 'Зменшення залишку', value: 'reduction_of_balance'},
    {text: 'Прискореного зменшення залишку', value: 'fast_reduction_of_balance'},
    {text: 'Кумулятивний', value: 'cumulative'},
]

const amortisation_calc_type_low_cost = [
    {text: '50% на 50%', value: 'fifty_to_fifty'},
    {text: '100%', value: 'hundred'},
]

const pay_system_template_type_select = [
    {text: 'Єдина квитанція (EPS)', value: 'eps'},
    {text: 'Єдина квитанція (EPS IPAY)', value: 'eps_ipay'},
    {text: 'ПриватБанк', value: 'privat_bank'},
    {text: 'Довільна', value: 'custom'},
]

const pay_system_row_type_select = [
    {text: 'Оплатити (особистий кабінет)', value: 'pay_button'}
]

const regulatory_report_period_type_select = [
    {text: 'I квартал', value: 'first_quarter'},
    {text: 'Півріччя', value: 'half_year'},
    {text: '9 місяців', value: 'month_9'},
    {text: 'Рік', value: 'year'},
]

const getMonthFromQuarter = (quarter) => {
    if (!quarter) return ''
    if (quarter === 1) return '01'
    if (quarter === 2) return '04'
    if (quarter === 3) return '07'
    if (quarter === 4) return '10'
}

const getMonthFromHalfOfYear = (quarter) => {
    if (!quarter) return ''
    if (quarter === 1) return '01'
    if (quarter === 2) return '07'
}

const getRegulatoryReportPeriodType = (value) => {
    const item = (regulatory_report_period_type_select || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getPaySystemTemplateType = (value) => {
    const item = (pay_system_template_type_select || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getPaySystemRowType = (value) => {
    const item = (pay_system_row_type_select || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getFixedGroup = (value) => {
    const item = (fixed_assets_group || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getCategoryName = (value) => {
    const item = (categoriesSelect || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getAbsentReason = (value) => {
    const item = (absentReasonSelect || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getBankIncomeOperation = (value) => {
    const item = (bank_document_income_operation || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getTaxBillIncomeOperation = (value) => {
    const item = (tax_bill_document_income_operation || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getTaxBillOutcomeOperation = (value) => {
    const item = (tax_bill_document_outcome_operation || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getEnteringBalancesOperation = (value) => {
    const item = (entering_balances_operation || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}


const getCommissioningOperation = (value) => {
    const item = (commissioning_document_operation || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getBankOutcomeOperation = (value) => {
    const item = (bank_document_outcome_operation || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getCashOrderOutcomeOperation = (value) => {
    const item = (cash_order_document_outcome_operation || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getCashOrderIncomeOperation = (value) => {
    const item = (cash_order_document_income_operation || []).find(i => i.value === value)
    if (!item) return ''
    return item.text
}

const getSplitType = (value) => {
    return (split_type_select.find(item => item.value === value) || {}).text || ''
}

const getAccountIcon = (name, type) => {
    const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
    const value = accountIcons[name] || defaultIcon
    return value[type]
}

const getCityIcon = (name, type) => {
    const defaultIcon = {icon: 'mdi-city', color: 'success'}
    const value = cityIcons[name] || defaultIcon
    return value[type]
}

const getAppealIcon = (value, type) => {
    const defaultIcon = {icon: 'mdi-archive-plus-outline', color: 'teal'}
    const item = appeal_status_type.find(i => i.value === value) || defaultIcon
    return item[type]
}

const getStreetIcon = (name, type) => {
    const defaultIcon = {icon: 'mdi-map-marker-check-outline', color: 'success'}
    const value = streetIcons[name] || defaultIcon
    return value[type]
}

const getUpdateInformationStatus = (value, type) => {
    const defaultIcon = {icon: 'mdi-account', color: 'success'}
    const item = update_information_status.find(i => i.value === value) || defaultIcon
    return item[type]
}


const getBuildingIcon = (name, type) => {
    const defaultIcon = {icon: 'mdi-map-marker-check-outline', color: 'success'}
    const value = buildingIcons[name] || defaultIcon
    return value[type]
}
const getContactIcon = (name, type) => {
    const defaultIcon = {icon: 'mdi-contacts', color: 'error lighten-1'}
    const value = contactIcons[name] || defaultIcon
    return value[type]
}

const getRegularDetails = (item) => {
    let details = ''

    if (item.only_square) {
        details += `на сім’ю: ${item.square_on_family}/ на людину: ${item.square_on_person}`
    }

    if (item.only_person) {
        details += `${item.param_on_family > 0 ? 'на сім’ю: ' + item.param_on_family + '/ ' : ''}`
        details += `${item.param_on_person > 0 ? 'на людину: ' + item.param_on_person + ' ' : ''}`
        details += `${item.param_max_value > 0 ? '/ макс.: ' + item.param_max_value + '/' : ''}`
    }

    if (item.param_on_square_counter) {
        details += `на 1 м.кв (з буд.ліч) ${item.param_on_square_counter}`
    }

    return details
}

const getVerificationType = (value, type='text') => {
    const defaultIcon = {icon: 'mdi-lock-alert-outline', color: 'secondary'}
    const item = verification_operation_type_select.find(i => i.value === value) || defaultIcon
    return item[type]
}

const getApplianceOperationType = (value, type='text') => {
    const defaultIcon = {icon: 'mdi-lock-alert-outline', color: 'secondary'}
    const item = appliance_operation_type_select.find(i => i.value === value) || defaultIcon
    return item[type]
}

const getApplianceType = (value, type='text') => {
    const defaultIcon = {icon: 'mdi-lock-alert-outline', color: 'secondary', text: 'Не вказаний ти приладу'}
    const item = appliance_type_select.find(i => i.value === value) || defaultIcon
    return item[type]
}

const getServiceIcon = (name, type) => {
    const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
    const value = accountIcons[name] || defaultIcon
    return value[type]
}

const format_date = (date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
    const day = date.getDate() + 1 < 10 ? '0' + date.getDate() : date.getDate()
    return `${year}-${month}-${day}`
}
const round_number = (value, precision) => {
    return Math.round((value + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision)
}


const endOfMonth = (date, return_type = 'string') => {
    if (date) {
        let new_date = endOfMonth_js_date(date)
        if (return_type === 'string') {
            return format_date(new_date)
        } else {
            return new_date
        }

    }
}

const beginOfMonth = (date, return_type = 'string') => {
    if (date) {
        let new_date = beginningOfMonth_js_date(date)

        if (return_type === 'string') {
            return format_date(new_date)
        } else {
            return new_date
        }
    }
}

const get_half_of_month = (date, first_half = true) => {
    if (date) {
        let new_date = beginningOfMonth_js_date(date)
        let end_of_month = endOfMonth_js_date(new_date)
        let half = 0

        const days_in_month = end_of_month.getDate()
        if (days_in_month === 31) {
            half = 15
        }
        if (days_in_month === 30) {
            half = 15
        }
        if (days_in_month === 29) {
            half = 14
        }
        if (days_in_month === 28) {
            half = 14
        }

        new_date.setDate(half)
        if (first_half) {
            return {
                date_start: format_date(beginningOfMonth_js_date(date)),
                date_end: format_date(new_date)
            }
        } else {
            new_date.setDate(half + 1)
            return {
                date_start: format_date(new_date),
                date_end: endOfMonth(beginningOfMonth_js_date(date))
            }
        }

    }
}

const addMothsToStringDate = (myDate, months) => {
    if (!myDate) return
    let date = getDateFromString(myDate)
    date.setMonth(date.getMonth() + months)

    return format_date(date)
}

const addDaysToStringDate = (myDate, days) => {
    if (!myDate) return
    let date = getDateFromString(myDate)
    date.setDate(date.getDate() + days)

    return format_date(date)
}

const beginningOfMonth_js_date = (myDate) => {
    let date
    if (typeof myDate === 'string') {
        date = getDateFromString(myDate)
    } else {
        date = new Date(myDate);
    }
    date.setDate(1)
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
}

const endOfMonth_js_date = (myDate) => {
    let date
    if (typeof myDate === 'string') {
        date = getDateFromString(myDate)
    } else {
        date = new Date(myDate);
    }

    date.setDate(1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(date.getDate() - 1);

    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
}

const getCurrentDate = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    return `${year}-${month}-${day}`
}

const getCurrentDateTime = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    const second = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}

const getDayFromDate = (myDate) => {
    let date

    if (typeof myDate === 'string') {
        date = getDateFromString(myDate)
    } else {
        date = new Date(myDate);
    }

    return date.getDate()
}

const diffMonths = (dt2, dt1, date_String = true) => {
    let date_2, date_1
    if (date_String) {
        date_2 = getDateFromString(dt2)
        date_1 = getDateFromString(dt1)
    } else {
        date_2 = dt2
        date_1 = dt1
    }
    date_1 = beginningOfMonth_js_date(date_1)
    date_2 = endOfMonth_js_date(date_2)

    return Math.max(
        (date_2.getFullYear() - date_1.getFullYear()) * 12 + date_2.getMonth() - date_1.getMonth() + 1,
        0
    )
}

const diffDays = (dt2, dt1, date_String = true) => {
    let date_2, date_1
    const oneDay = 1000 * 60 * 60 * 24

    if (!dt2 || !dt1) return 0

    if (date_String) {
        date_2 = getDateFromString(dt2)
        date_1 = getDateFromString(dt1)
    } else {
        date_2 = dt2
        date_1 = dt1
    }

    const diffInTime = date_2.getTime() - date_1.getTime()
    return Math.round(diffInTime / oneDay);
}

const getEndOfYear = (myDate) => {
    let date
    if (typeof myDate === 'string') {
        date = getDateFromString(myDate)
    } else {
        date = new Date(myDate);
    }

    date.setMonth(12)
    date.setDate(0)
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    return format_date(date)
}

const format_date_from_python = (payload) => {
    if (!payload) return null
    if (payload.indexOf('T') === -1) {
        const split = payload.split('-')
        return `${split[2]}.${split[1]}.${split[0]}`
    } else {
        const date = payload.substring(0, 10)
        const date_split = date.split('-')
        return `${date_split[2]}.${date_split[1]}.${date_split[0]}`
    }
}


const getDateFromString = (payload) => {
    if (!payload) return null

    if (payload.indexOf('T') === -1) {
        const splited = payload.split('-')
        return new Date(+splited[0], (+splited[1]) - 1, +splited[2])
    } else {
        const date = payload.substring(0, 10)
        const time = payload.substring(11)

        const date_split = date.split('-')
        const time_split = time.split(':')

        return new Date(+date_split[0], (+date_split[1]) - 1, +date_split[2], +time_split[0], +time_split[1], +time_split[2])
    }

}

const getMonetaryTypeTranslate = (payload, return_case = true) => {
    if (!payload) return ''
    if (payload === 'privilege') {
        return return_case ? 'пільги' : 'Пільга'
    } else {
        return return_case ? 'субсидії' : 'Субсидія'
    }
}

const monetaryTypeSelect = [
    {text: 'Пільга', value: 'privilege'},
    {text: 'Субсидія', value: 'subsidy'},
]

const voting_types_select = [
    {text: 'Опитування', value: 'POLL', color: 'success'},
    {text: 'Голосування', value: 'VOTING', color: 'secondary'},
    {text: 'Інформування', value: 'INFORMING', color: 'primary'},
]

const management_group_select = [
    {text: 'Член ревізійної комісії', value: 'revision_commission'},
    {text: 'Член правління', value: 'member_of_board'},
    {text: 'Без групи', value: 'without'},
]

const social_privilege_select = [
    {text: 'Базова пільга 100% (пп. 169.1.1 ПКУ)', value: 'privilege_169_1_1_100'},
    {text: 'Для осіб, які утрим. 2 та більше дітей 100% (пп. 169.1.2 ПКУ)', value: 'privilege_169_1_2_100'},
    {text: 'Для одиноких матерів(батьків), опікунів 150% (пп. 169.1.3а ПКУ)', value: 'privilege_169_1_3a_150'},
    {
        text: 'Для осіб, I-II групи, які постраждали від Чорноб.катастрофи 150% (пп. 169.1.3б ПКУ)',
        value: 'privilege_169_1_3b_150'
    },
    {
        text: 'Для особи, яка є учнем, студентом, ординатором, ад’юктом 150% (пп. 169.1.3г ПКУ)',
        value: 'privilege_169_1_3g_150'
    },
    {
        text: 'Для особи, яка є інвалідами I-II групи, в т.ч з дитинства 150%  (пп. 169.1.3ґ ПКУ)',
        value: 'privilege_169_1_3g1_150'
    },
    {
        text: 'Для осіб, яким присуджено пожиттєву стипендію за переслід. 150%  (пп. 169.1.3д ПКУ)',
        value: 'privilege_169_1_3d_150'
    },
    {
        text: 'Для осіб, які є учас.бой. дій після Другої світової війни 150%  (пп. 169.1.3е ПКУ)',
        value: 'privilege_169_1_3e_150'
    },
    {
        text: 'Для осіб, які є героєм України, Рад.Союзу, Соціал.праці (200%)  (пп. 169.1.4а ПКУ)',
        value: 'privilege_169_1_4a_200'
    },
    {
        text: 'Для осіб, які є учас.бой дій під час Другої світової війни (200%)  (пп. 169.1.4б ПКУ)',
        value: 'privilege_169_1_4b_200'
    },
    {
        text: 'Для осіб, які є колиш. в’язнями концтаборів, гетто (200%)  (пп. 169.1.4в ПКУ)',
        value: 'privilege_169_1_4v_200'
    },
    {
        text: 'Для осіб, які буди примус. вивезені із територ. СРСР (200%)  (пп. 169.1.4г ПКУ)',
        value: 'privilege_169_1_4g_200'
    },
    {
        text: 'Для осіб, які перебували на блокадній терит. кол. Ленінграда(Санкт-Петербуро) (200%)  (пп. 169.1.4ґ ПКУ)',
        value: 'privilege_169_1_4g1_200'
    },
]

const getPrivilegeType = (name, percent = false) => {
    const item = (social_privilege_select.find(item => item.value === name) || {}).text || ''
    if (!percent) {
        return item
    } else {
        if (!item) {
            return ''
        } else {
            const position = name.lastIndexOf('_')
            return name.substr(position + 1)
        }
    }
}

const getManagementGroup = (payload) => {
    return (management_group_select.find(item => item.value === payload) || {}).text || ''
}

const getVotingType = (payload, col = 'text') => {
    return (voting_types_select.find(item => item.value === payload) || {})[col] || ''
}

const budget_select = [
    {text: 'Державний', value: 'national'},
    {text: 'Міський', value: 'private'}
]

const getBudgetType = (name) => {
    return (budget_select.find(item => item.value === name) || {}).text || ''
}

const debounce = (func, delay = 600, immediate = false) => {
    let timeout
    return function () {
        const context = this
        const args = arguments
        const later = function () {
            timeout = null
            if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, delay)
        if (callNow) func.apply(context, args)
    }
}

const sms_service_sender_type_select = [
    { text: 'Vodafone UA', value: 'vodafone' },
    { text: 'Lifecell UA', value: 'lifecell' },
    { text: 'Київстар', value: 'kiyvstar' },
]

const sms_authorization_type_select = [
    { text: 'Bearer token', value: 'bearer' },
    { text: 'Пароль', value: 'password' }
]

const sms_send_message_type_select = [
    { text: 'sms', value: 'sms' },
    { text: 'viber', value: 'viber' }
]

const dispatcher_call_type_select = [
    { text: 'Зміна даних', value: 'data_change' },
    { text: 'Виконання робіт ', value: 'do_work' },
]


const dispatcher_priority_select = [
    { text: 'Низький', value: 'low', color: 'primary', icon: 'mdi-account-circle-outline' },
    { text: 'Середній', value: 'medium', color: 'teal', icon: 'mdi-account-circle-outline' },
    { text: 'Високий', value: 'high', color: 'secondary', icon: 'mdi-account-circle-outline' },
    { text: 'Надвисокий', value: 'ultra_high', color: 'error', icon: 'mdi-account-circle-outline' },
]

const dispatcher_status_select = [
    { text: 'Зареєстрована', value: 'registered', color: 'teal' },
    { text: 'Опрацьовується', value: 'under_development', color: 'secondary' },
    { text: 'Виконується', value: 'in_progress', color: 'orange' },
    { text: 'Виконана', value: 'completed', color: 'success' },
    { text: 'Завершена', value: 'ended', color: 'primary' },
    { text: 'Відмінена', value: 'canceled', color: 'error' },
]

const dispatcher_person_type_select = [
    { text: 'Абонент із адресою', value: 'with_address' },
    { text: 'Абонент без адреси ', value: 'without_address' },
]

const getDispatcherPersonType = (value, col = 'text') => {
    return (dispatcher_person_type_select.find(item => item.value === value) || {})[col] || ''
}

const getDispatcherCallType = (value, col = 'text') => {
    return (dispatcher_call_type_select.find(item => item.value === value) || {})[col] || ''
}

const getDispatcherPriority = (value, col = 'text') => {
    return (dispatcher_priority_select.find(item => item.value === value) || {})[col] || ''
}

const getDispatcherStatus = (value, col = 'text') => {
    return (dispatcher_status_select.find(item => item.value === value) || {})[col] || ''
}

const getSmsServiceSenderType = (value, col = 'text') => {
    return (sms_service_sender_type_select.find(item => item.value === value) || {})[col] || ''
}

const getSmsAuthorizationType = (value, col = 'text') => {
    return (sms_authorization_type_select.find(item => item.value === value) || {})[col] || ''
}

const getSmsSendMessageTypeType = (value, col = 'text') => {
    return (sms_send_message_type_select.find(item => item.value === value) || {})[col] || ''
}


let SERVER_PUBLIC_KEY = 'BMXenfl3-PZOLUjbbujgNfdHCND8okNl9nRZWLq615Ogp34x2pXkCE13I0qmGvc3VULqtA7UU6pheE2aig03aw4'

const urlBase64ToUint8Array = function (base64String) {
    let padding = '='.repeat((4 - base64String.length % 4) % 4);
    let base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

const updateSubscriptionOnServer = (api, subscription) => {
    const url = window.location.origin
    return api.subscribe({
        subscription_json: JSON.stringify(subscription),
        url: url
    });
}

const subscribeToPush = (swRegistration, applicationServerPublicKey, api) => {
    const applicationServerKey = urlBase64ToUint8Array(applicationServerPublicKey);

    const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey
    }

    swRegistration.pushManager.getSubscription()
        .then(subscription_exist => {
            if (!subscription_exist) {
                swRegistration.pushManager.subscribe(subscribeOptions)
                    .then((subscription) => {
                        updateSubscriptionOnServer(api, subscription)
                            .then(() => {
                                console.log('subscribe success no')
                            })
                            .catch(() => {
                                console.log('subscribe error no')
                            })
                    })
            } else {
                swRegistration.pushManager.subscribe(subscribeOptions)
                    .then((subscription) => {
                        updateSubscriptionOnServer(api, subscription)
                            .then(() => {
                                console.log('subscribe success')
                            })
                            .catch(() => {
                                console.log('subscribe error')
                            })
                    })
            }
        })
}

const requestPermission = function() {
    return new Promise(function(resolve, reject) {
        const permissionResult = Notification.requestPermission(function(result) {
            resolve(result);
        });
        if (permissionResult) {
            permissionResult.then(resolve, reject);
        }
    })
}

export {
    SERVER_PUBLIC_KEY,
    urlBase64ToUint8Array,
    updateSubscriptionOnServer,
    subscribeToPush,
    requestPermission,
    cityTypes,
    cityTypesShort,
    cityTypes_select,
    streetTypes,
    streetTypesShort,
    streetTypes_select,
    buildingTypes,
    buildingTypes_select,
    flat_ownership_types_select,
    accountIcons,
    accountIconSelect,
    cityIcons,
    streetIcons,
    buildingIcons,
    registrationType,
    registrationTypeSelect,
    calculateMethods,
    calculateMethodsSelect,
    absentReasonSelect,
    regionSelect,
    bankAccountTypes,
    bankAccountTypesSelect,
    categoriesSelect,
    contactTypeSelect,
    addCounterValueMethod,
    addBuildingCounterValueMethod,
    document_ownership_types_select,
    ext_obj,
    buildingCalcMthod,
    voting_types_select,
    split_type_select,
    budget_select,
    subconto_types,
    balance_types,
    warehouse_income_operation,
    bank_document_income_operation,
    tax_bill_document_income_operation,
    tax_bill_document_outcome_operation,
    bank_document_outcome_operation,
    salary_to_pay_operation,
    personal_timesheet_document_fill_type,
    monetaryServiceSelect,
    monetaryTypeSelect,
    management_group_select,
    statusTypeSelect,
    social_calc_type_select,
    mediaSendStatusSelect,
    mediaSendTypeSelect,
    mediaReplaceParams,
    fixed_assets_group,
    fixed_assets_group_only_fixed,
    fixed_assets_group_only_low_cost,
    work_transfer_accrual_type,
    accrual_type_select,
    accrual_tax_group_select,
    contract_pay_type_select,
    social_privilege_select,
    desktop_tree_type_select,
    accrual_count_type_select,
    accrual_group_select,
    appeal_status_type,
    appeal_status_type_admin,
    flat_note_type_select,
    hospital_type,
    pdv_items,
    vocation_type,
    vocation_type_accrual,
    advance_report_document_type,
    cash_order_document_outcome_operation,
    cash_order_document_income_operation,
    commissioning_document_operation,
    amortisation_calc_type_fixed,
    amortisation_calc_type_low_cost,
    entering_balances_operation,
    warehouse_outcome_type,
    tax_bill_income_document_type,
    tax_bill_spec_mode,
    tax_bill_document_type,
    divideMethods,
    tax_bill_consolidate_type,
    tax_bill_not_issue_type,
    tax_bill_calculated_form,
    tax_bill_contract_type,
    tax_bill_table_code_type,
    tax_bill_outcome_document_type,
    districtSelect,
    pay_system_template_type_select,
    pay_system_row_type_select,
    update_information_module_type,
    update_information_status,
    registration_of_changes_section_type,
    verification_operation_type_select,
    appliance_type_select,
    appliance_operation_type_select,
    regulatory_report_period_type_select,
    flat_note_result_type_select,
    court_case_stage_of_enforcement_type_select,
    court_case_resolution_type_select,
    court_case_document_type_select,
    court_case_table_type_select,
    pretense_status_select,
    dispatcher_person_type_select,
    dispatcher_status_select,
    dispatcher_call_type_select,
    dispatcher_priority_select,
    sms_service_sender_type_select,
    sms_authorization_type_select,
    sms_send_message_type_select,
    getRegulatoryReportPeriodType,
    format_date_from_python,
    getTaxBillIncomeOperation,
    getTaxBillOutcomeOperation,
    getEnteringBalancesOperation,
    getCommissioningOperation,
    getCategoryName,
    getCurrentDateTime,
    diffDays,
    getDayFromDate,
    get_hospital_type,
    get_salary_to_pay,
    getAbsentReason,
    diffMonths,
    getEndOfYear,
    getAppealIcon,
    onBeforeUnload,
    getPrivilegeType,
    get_accrual_type,
    get_accrual_tax_group,
    get_half_of_month,
    getFixedGroup,
    getMediaSendType,
    getMediaSendStatus,
    getBankIncomeOperation,
    getBankOutcomeOperation,
    format_date,
    check_show_page,
    getStatusType,
    getManagementGroup,
    getBalanceType,
    getSubcontoType,
    getSplitType,
    getVotingType,
    getAddCounterValueMethod,
    getAddBuildingCounterValueMethod,
    getCashOrderOutcomeOperation,
    getCashOrderIncomeOperation,
    getAccountIcon,
    getCityIcon,
    getStreetIcon,
    getBuildingIcon,
    getContactIcon,
    getFlatType,
    getRegularDetails,
    endOfMonth,
    beginOfMonth,
    getCurrentDate,
    getDateFromString,
    addMothsToStringDate,
    getBudgetType,
    getMonetaryTypeTranslate,
    round_number,
    addDaysToStringDate,
    get_vocation_type,
    generateIconSelect,
    getPaySystemTemplateType,
    getPaySystemRowType,
    getUpdateInformationStatus,
    getRegistrationOfChangesSectionType,
    getVerificationType,
    getApplianceType,
    getServiceIcon,
    getApplianceOperationType,
    getMonthFromQuarter,
    getMonthFromHalfOfYear,
    getFlatNoteType,
    getFlatNoteResultType,
    getPretenseStatus,
    getCourtCaseStageOfEnforcement,
    getCourtCaseDocument,
    getDispatcherPriority,
    getDispatcherCallType,
    getDispatcherPersonType,
    getDispatcherStatus,
    getSmsServiceSenderType,
    getSmsAuthorizationType,
    getSmsSendMessageTypeType,
    debounce
}
