import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/appeal';

export default {
    get_all(payload) {
        return axios.post(`${RESOURCE_NAME}/all`, payload)
    },
    create(payload) {
        return axios.post(`${RESOURCE_NAME}/`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    update(payload) {
        return axios.post(`${RESOURCE_NAME}/update`, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
    },
    update_read(payload) {
        return axios.post(`${RESOURCE_NAME}/update-read`, { appeal_id: payload })
    },
    rate(payload) {
        return axios.post(`${RESOURCE_NAME}/update-rate`, payload)
    },
    remove(payload) {
        return axios.post(`${RESOURCE_NAME}/remove`, { appeal_id: payload })
    },
    appeal_add(payload) {
        return axios.post(`${RESOURCE_NAME}/appeal`, { question_id: payload })
    },
    appeal_comment(payload) {
        return axios.get(`${RESOURCE_NAME}/appeal-comment`, {params: {appeal_id: payload}})
    },
    appeal_comment_create(payload) {
        return axios.post(`${RESOURCE_NAME}/appeal-comment`, payload)
    },
}
